import React, { Component } from 'react'
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";  
import fr from "date-fns/locale/fr"; // the locale you want  
import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr", fr); // register it with the name you want

class InputField extends Component {
    
    
    componentDidMount() {
         
    }
    render() {
        const Options = this.props;
        
        if(Options.type === "textarea"){
            return (
                <div className={"form-group"+Options.classContainer}>
                <textarea 
                    className={"_form-control " + Options.classInput} 
                    name={Options.name} 
                    id={Options.id}
                    onChange={Options.oninput}
                    valeur={Options.value} value={Options.value}>{Options.value}</textarea>
                    
                    <label htmlFor={Options.id}>{Options.label}</label>
                    <div className="errorMsg">{Options.errorMsg}</div>
                </div>
            )
        }else if(Options.type === "select"){
            
            return (
                <div className={"form-group " + Options.classContainer}>
                    <Select
                        name={Options.name}
                        className={"_form-control " + Options.classInput}
                        id={Options.id}
                        placeholder={Options.placeholder}
                        //value={Options.value ? Options.value : ''}
                        defaultValue={Options.default}
                        onChange={Options.oninput}
                        options={Options.items} 
                    />
                    {Options.label && 
                        <label className={Options.fixedLabel ? "fixedLabel" : ""} htmlFor={Options.id}>{Options.label}</label>
                    }
                    <div className="errorMsg">{Options.errorMsg}</div>
                </div>
            )
        }else if(Options.type === "date"){
           
            return (
                <div className={"form-group " + Options.classContainer + " " + Options.errorClass}>
                    <label htmlFor={Options.id}>{Options.label}</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                        name={Options.name}
                        className={"_form-control " + Options.classInput}
                        id={Options.id} 
                        placeholder={Options.placeholder}
                        onChange={Options.oninput}
                        showYearDropdown
                        showMonthDropdown
                        selected={Options.value}
                        minDate={Date.parse('01/01/1920')}
                        popperPlacement={Options.popperPlacement || 'bottom-start'} 
                    /> 
                    <div className="errorMsg">{Options.errorMsg}</div>
                </div>
            )
        } else {
            return (
                <div className={"form-group " + Options.classContainer}>
                    {Options.fixedLabel === "true" &&
                        <input 
                            type={Options.type} 
                            className={"_form-control " + Options.classInput} 
                            name={Options.name} 
                            id={Options.id} />
                    }
                    {Options.fixedLabel === "false" &&
                        <input 
                            type={Options.type} 
                            className={"_form-control " + Options.classInput} 
                            name={Options.name} 
                            id={Options.id} 
                            placeholder={Options.placeholder}
                            onChange={Options.oninput}
                            value={Options.value} />
                    }
                    
                    <label htmlFor={Options.id}>{Options.label}</label>
                    <div className="errorMsg"  dangerouslySetInnerHTML={{__html: Options.errorMsg}}></div>
                </div>
            )
        }
    }
}

export default InputField;