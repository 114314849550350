import React, { useEffect, useState } from 'react'  
import Global from '../../../inc/Global'
import Axios from 'axios';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//components
import {Form, Row, Col, Button, Dropdown} from 'react-bootstrap'; 
import BeatLoader from "react-spinners/BeatLoader";


const Headband = (props)=>{

    //State 
    const [isActive, setActive] = useState(false) 
    const [listing, setListing] = useState([]) 
    const [type, setType] = useState('Image')
    const [file, setFile] =  useState(null)
    const [text, setText] =  useState(null)
    const [link, setLink] =  useState('')
    const [addingItem, setAddingItem] =  useState(false)
    const [action, setAction] = useState('add')
    const [id, setId] =  useState(null)
    const [isLoaded, setLoaded] = useState(false)

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    


    useEffect(() => { 
        getListing()
    }, []); 

    const getListing = ()=>{
        Axios.get(Global.API_URL + "/bandeau").then((res) => {
            if( res.data.status ){
                setActive(true)   
            } 
            if( res.data.result ){ 
                setListing(res.data.result)   
            } 
            setLoaded(true)
        })
    } 

    const deleteItem = (item)=>{
        Axios.delete(Global.API_URL + "/api/deletebandeau/"+item.id).then((res) => {
            if( res.data.status ){ 
                getListing()
            } 
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()  
        setAddingItem(true)

        let data = new FormData() 

        switch(type){
            case 'Image':  
            case 'Vidéo': 
                data.append('type', 'file') 
                data.append('idbandeau', id) 
                data.append('file', file)  
                data.append('lien', type == 'Image' ? link : '') 
                break;
            case 'Texte': 
                data.append('type', 'text') 
                data.append('idbandeau', id)   
                data.append('text', text)   
                break;
        } 
        ///
        Axios.post(Global.API_URL + "/api/admin/bandeau",data).then((res) => {
            getListing();
            resetForm()
            setAddingItem(false) 
        })
    } 

    const resetForm = ()=>{
        document.getElementById("add-headband").reset(); 
        setType('Image')
        setLink(null)
        setFile(null)  
        setText(null)  
        setAction('add');
        setId(null)
    }

    const changeHeadBandStatus = ()=>{
        setActive(!isActive) 
        Axios.post(Global.API_URL + "/api/desactanimation/2", {}).then((res) => {

        })
    }

    const onEditorStateChange = (editorState)=>{ 
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setText(html)
        setEditorState(editorState)
    }

    if( !isLoaded ) return (
        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
    )
    else return(
        <div className="administration-headband">
            <h2>Bandeau</h2>
            <p>Permet de modifier l'affichage du bandeau.</p>
            
            <div className="activate-option d-flex jc-space-b align-center">
                <label htmlFor="_anonyme-parti">Afficher le bondeau sur la page d'accueil</label>

                <div className="param d-flex align-center">
                    <div className="radio-button">
                        <input type="checkbox" onChange={(e) => {changeHeadBandStatus()}} name="_anonyme-parti" id="_anonyme-parti" checked={isActive} />
                        <label htmlFor="_anonyme-parti">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            
            
                {
                    action == 'add' &&
                    <div className="listing">  

                        <Row> 
                            <Col xs={4}>Illustration</Col> 
                            <Col xs={6}></Col> 
                            <Col xs={2}>Action</Col> 
                        </Row> 
                        {listing.map(item=>(
                            <Row> 
                                <Col xs={item.text ? 10 : 4}> 
                                    {
                                        item.bandeau &&
                                        <div className="item-image" style={{backgroundImage: `url(${Global.API_URL}/${item.bandeau})`}}></div>
                                    }
                                    {
                                        item.video &&
                                        <div className="item-image" style={{backgroundImage: `url(${Global.API_URL}/${item.thumbs})`}}></div>   
                                    } 
                                    {
                                        item.text &&
                                        <div className="item-text" dangerouslySetInnerHTML={{__html: item.text}}></div>   
                                    }  
                                </Col> 
                                {
                                    !item.text && 
                                    <Col xs={6} className="link">
                                        {
                                            item.bandeau &&
                                            <a href={item.link} target="_blank">{ item.link }</a>
                                        }
                                    </Col> 
                                } 
                                <Col xs={2}>
                                    <div className="d-flex">
                                        <button className="edit-item" 
                                            onClick={()=>{
                                                setAction('edit'); 
                                                setType( item.text ? 'Texte' :  item.bandeau ? 'Image' : 'Vidéo' )
                                                setId(item.id) 
                                                setLink(item.link) 

                                                if(item.text){
                                                    setText(item.text)
                                                    
                                                    let contentBlock = htmlToDraft(item.text);
                                                    if (contentBlock) {
                                                        let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                                                        let editorState = EditorState.createWithContent(contentState);
                                                        setEditorState(editorState)
                                                    }
                                                }

                                            }}
                                        >
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <button className="delete-item" onClick={()=>{deleteItem(item)}}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </Col> 
                            </Row> 
                        ))}
                    </div>
                }

                <Form onSubmit={ onSubmit } id="add-headband">  
                    { action == 'add' ? <h2>Nouvelle Illustration</h2>  : <h2>Modification illustration</h2> }  
                    <Row> 
                        <Col>
                            <Form.Group> 
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    { type }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={()=>{setType('Image')}}>Image</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={()=>{setType('Vidéo')}}>Vidéo</Dropdown.Item> 
                                    <Dropdown.Item href="#" onClick={()=>{setType('Texte')}}>Texte</Dropdown.Item> 
                                </Dropdown.Menu>
                            </Dropdown>
                            </Form.Group> 
                        </Col> 
                    </Row> 
                    {
                        (type == 'Image' || type == 'Vidéo') &&
                        <Row> 
                            <Col>
                                <Form.Group> 
                                    <Form.File 
                                    accept={type == 'Image' ? 'image/*' : 'video/*'}
                                    required
                                    onChange={(e)=>{ 
                                        setFile(e.target.files[0])
                                    }}
                                    name="logo" id="logo" label={ type == 'Image' ? 'Illustration' : 'Video'} />
                                </Form.Group> 
                            </Col> 
                        </Row> 
                    } 

                    {
                        type == 'Texte' &&
                        <Row> 
                            <Col>
                                <Form.Group>
                                    <Form.Label>Texte</Form.Label> 
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="react-editor"
                                        onEditorStateChange={onEditorStateChange}
                                    /> 
                                </Form.Group> 
                            </Col> 
                        </Row> 
                    } 

                    {
                        type == 'Image' &&
                        <Row> 
                            <Col>
                                <Form.Group>
                                    <Form.Label>Lien</Form.Label>
                                    <Form.Control 
                                    type="text" name="link" id="link" placeholder="" value={link} 
                                    onChange={(e)=>{setLink(e.target.value)}} /> 
                                </Form.Group> 
                            </Col> 
                        </Row> 
                    }
                    <div className="submit-container">
                        <Button type="reset" onClick={()=>{resetForm()}}>
                            Annuler
                        </Button> 
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="primary" type="submit" disabled={addingItem}>
                            { action == 'add' ? 'Ajouter'  : 'Modifier' }
                            <BeatLoader color={"#aaa"} loading={addingItem} size="10"/>
                        </Button>
                    </div>
                </Form>
                  
        </div>
    )
}

export default Headband