import Axios from 'axios'
import React, { useRef, useState } from 'react'
import Global from '../../inc/Global'
import InputField from '../InputField'
import classnames from "classnames";
import TextareaAutosize from 'react-textarea-autosize'; 
import DatePicker , { registerLocale, setDefaultLocale } from 'react-datepicker';


import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

const AddEvent = (props) => { 
    
    const RoleSelect = props.RoleSelect

    const [communityID, setCommunityID] = useState(props.communityID) 

    // err handler
    const [communityIsSelected, setCommunityIsSelected] = useState(true) 
    const [titleIsSelected, setTitleIsSelected] = useState(true) 
    const [placeIsSelected, setPlaceIsSelected] = useState(true) 
    const [dateDebutIsSelected, setDateDebutIsSelected] = useState(true) 
    const [dateFinIsSelected, setDateFinIsSelected] = useState(true) 
    
    // Post Data
    const [title, setTitle] = useState()
    const [place, setPlace] = useState()
    const [description, setDescription] = useState()
    
    //image
    const inputImage = useRef();
    const [selectedImage, setSelectedImage] =  useState(null)
    
    // Upload progress
    const [uploadProgress, setUploadProgress] = useState(30)
    
    // Error
    const [errorMsg, setErrorMsg] = useState(null)
    
    const [startDatePiker, setStartDatePiker] = useState();
    const [endDatePiker, setEndDatePiker] = useState();
   
   
    const handleSetNewEndDate = (date)=>{
        if (!endDatePiker || endDatePiker < date ) {
            const dt = new Date(date);
            setEndDatePiker(new Date(dt.getTime() + 60*60000))
        }
    }
    const create = ()=>{
        
        if( communityID === 0 ){
            setCommunityIsSelected(false)
            setErrorMsg("Veuillez sélectionner une communauté.");
            return
        }else{
            setCommunityIsSelected(true)
        }

        if(!title){
            setTitleIsSelected(false);
            setErrorMsg("errrrrebaefsbvdv. a dva dvna s");
            return
        } 
        else{
            setTitleIsSelected(true)
        }


        if(!place){
            setPlaceIsSelected(false);
            setErrorMsg("errrrrebaefsbvdv. a dva dvna s");
            return
        } 
        else{
            setPlaceIsSelected(true)
        }

        if(!startDatePiker){
            setDateDebutIsSelected(false);
            setErrorMsg("errrrrebaefsbvdv. a dva dvna s");
            return
        } 
        else{
            setDateDebutIsSelected(true)
        }


        if(!endDatePiker){
            setDateFinIsSelected(false);
            setErrorMsg("errrrrebaefsbvdv. a dva dvna s");
            return
        } 
        else{
            setDateFinIsSelected(true)
        }

        if(startDatePiker>=endDatePiker){
            setDateFinIsSelected(false);
            setErrorMsg("errrrrebaefsbvdv. a dva dvna s");
            return
        } 
        else{
            setDateFinIsSelected(true)
        }

        setErrorMsg(null)


        let data = new FormData();
        data.append('text', title) 
        data.append('lieu', place) 
        data.append('description', (description? description: '')) 
        data.append('datedebut', new Date(startDatePiker).toISOString()) 
        data.append('datefin', new Date(endDatePiker).toISOString()) 
        data.append('idgroup', communityID) 
        
        if(selectedImage) data.append('file', selectedImage)

        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent <= 100 ){
                    setUploadProgress(percent)
                }
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        Axios
        .post(Global.API_URL+'/api/eventcreate', data, options)
        .then((res) => {
            if(res.data.status){
                props.resetFotm()
                props.onSubmit({
                    data:[{post: res.data.event}]
                })
            }
        }) 
    }

    return(
        <div 
            className={`create-post add-new-event  add-new-poll active ${props.communityID !== 0 ? 'create-community-post' : ''}`}>
            <span className="trs-color"></span>
            <span className="cancel-post" onClick={()=>{props.resetFotm()}}>
                <i className="fa fa-close"></i>
            </span>  
            { props.communityID === 0 ? 
                <div className="title-create-post">
                    <div className="jc-space-b align-center f-wrap-xs">
                        <div className=""> 
                                <InputField
                                    type="select" 
                                    id={"_community"}
                                    name="community" 
                                    label="" 
                                    fixedLabel="true" 
                                    placeholder=""
                                    items={RoleSelect.items}
                                    classInput="" 
                                    classContainer={!communityIsSelected ? "error" : "" } 
                                    value={RoleSelect.selectedRole}  
                                    default={RoleSelect.selectedRole} 
                                    oninput={(e)=>{setCommunityID(e.value); setCommunityIsSelected(true); setErrorMsg(null)}} 
                                    errorMsg="" /> 
                        </div>
                    </div>
                </div>
                : 

                <input 
                    type="hidden"
                    id={"_community"}
                    name="community"
                    value={communityID}
                /> 

                }
            <div className="content-post"> 
                
                
                <div className="textarea"> 
                    <div className="wrapper"> 
                        {
                            selectedImage &&
                            <div className="poll-image">
                                <img src={URL.createObjectURL(selectedImage)} alt="" />
                                <button onClick={()=>{setSelectedImage(null)}}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        } 

                    <div className="responses eventInfo">
                        <ul>
                            <li className="d-flex" >
                                <div className="input-wrapper">
                                    <input
                                    type="text"
                                    autoFocus 
                                    placeholder={`Nom de l'evenement`}
                                    value={title} 
                                    onChange={(e)=>{setTitle(e.target.value)}} 
                                    className={!titleIsSelected ? "error" : "" } 
                                    />
                                </div>
                            </li>
                            <li className="d-flex" >
                                <div className="input-wrapper">
                                    <input
                                    type="text" 
                                    placeholder={`Lieu de l'evenement`}
                                    value={place} 
                                    onChange={(e)=>{setPlace(e.target.value)}} 
                                    className={!placeIsSelected ? "error" : "" } 
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                        <span className="figure" style={{backgroundImage: 'url('+ Global.API_URL+"/" + props.auth.user.profil +')' }}>
                            { props.auth.user.profil === '0' ? props.auth.user.name.charAt(0) + props.auth.user.lastname.charAt(0) : ''} 
                        </span>
                    </div> 
                </div>  
                
                <div className="responses eventInfo">
                    {/* <ul>
                        <li className="d-flex" >
                            <div className="input-wrapper">
                                <input
                                type="text" 
                                placeholder={`Titre de l'evenement`}
                                value={title} 
                                onChange={(e)=>{setTitle(e.target.value)}} 
                                 />
                            </div>
                        </li>
                        <li className="d-flex" >
                            <div className="input-wrapper">
                                <input
                                type="text" 
                                placeholder={`Titre de l'evenement`}
                                value={place} 
                                onChange={(e)=>{setPlace(e.target.value)}} 
                                />
                            </div>
                        </li>
                    </ul> */}
                    
                    <div className="dateSection">
                        <div className="date-end-poll">
                            {/* <label>Date debut de l'evenement</label> */}
                            <DatePicker
                                    locale="fr"
                                    isClearable
                                    disabledKeyboardNavigation
                                    selected={startDatePiker}
                                    onChange={(date) => {setStartDatePiker(date);handleSetNewEndDate(date)}}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="d MMMM yyyy HH:mm"
                                    // dateFormat="MMMM d, yyyy HH:mm"
                                    placeholderText="Date et heure de début"
                                    className={(!dateDebutIsSelected  || startDatePiker>=endDatePiker)? "error datetimePickerEvent" : "datetimePickerEvent" } 
                                    />
                        </div>
                        <div className="date-end-poll">
                            {/* <label>Date clôture de l'evenement</label> */}
                            <DatePicker
                                    locale="fr"
                                    placeholderText="Date et heure de fin"
                                    isClearable
                                    disabledKeyboardNavigation
                                    selected={endDatePiker}
                                    onChange={(date) => setEndDatePiker(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    // dateFormat="MMMM d, yyyy HH:mm"
                                    dateFormat="d MMMM yyyy HH:mm"
                                    className={(!dateFinIsSelected || startDatePiker>=endDatePiker) ? "error datetimePickerEvent" : "datetimePickerEvent" } 
                                    />
                        </div>
                    </div>
                    <div className="textarea event mb20px"> 
                        <div className="wrapper"> 
                            

                            <TextareaAutosize   
                                value={description} 
                                onChange={(e)=>{setDescription(e.target.value)}} 
                                rows="1"  
                                placeholder="Ajouter une description , un lien ..." 
                                className="text-post"
                                />
                        </div> 
                    </div> 
                    {
                        errorMsg &&
                        <div className="error-msg"> {errorMsg} </div>
                    }  
                    
                </div>
                {
                    uploadProgress > 0 && selectedImage &&
                    <div className="loaderProgress">
                        <div className="progressBar">
                            <span style={{width: uploadProgress + "%"}}></span>
                        </div>
                    </div>
                } 
                </div><div className="ft-create-post eventSection">
                    <button className="back-to-post" onClick={()=>{props.hideEventForm()}}>
                        <span className="fa fa-arrow-left"></span> 
                        Publication
                    </button>
                    <ul> 
                        <li>
                            <a href>
                                <label htmlFor="uploadImages">
                                    <span className="fa fa-picture-o"></span>
                                    <input 
                                        ref={inputImage}
                                        type="file" 
                                        name="uploadImages[]" 
                                        id="uploadImages" 
                                        accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                        onChange={(e)=>{setSelectedImage(e.target.files[0])}} />
                                    Ajouter une photo
                                </label>
                            </a>
                        </li> 
                        <li>
                            <button onClick={()=>{create()}} className="button"><span>Publier</span></button>
                        </li>
                    </ul>
                </div>
            
        </div>
    )
}

export default AddEvent