import React, { useEffect, useState } from 'react'  
import Global from '../../../inc/Global'
import Axios from 'axios';
import SHA1 from 'sha1'

//components
import {Form, Row, Col, Button, Dropdown} from 'react-bootstrap'; 
import BeatLoader from "react-spinners/BeatLoader";
import ProfileMenu from '../../header/ProfileMenu';
 


const Links = (props)=>{

    //State
    const [listing, setListing] = useState([]) 
    const [name, setName] = useState('')
    const [action, setAction] = useState('add')
    const [id, setId] =  useState(null)
    const [link, setLink] =  useState('')
    const [icon, setIcon] =  useState(null) 
    const [addingItem, setAddingItem] =  useState(false) 

    useEffect(() => { 
        getListing()
    }, []); 

    const getListing = ()=>{
        Axios.get(Global.API_URL + "/api/getlink").then((res) => {
            if( res.data.status ) setListing(res.data.lien)  
        })
    } 

    const deleteItem = (item)=>{
        Axios.delete(Global.API_URL + "/api/deletelink/"+item.id).then((res) => {
            if( res.data.status ){ 
                getListing()
                ProfileMenu.refrechLinks()
            } 
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()  
        setAddingItem(true)

        let data = new FormData()
        if( id ) data.append('idlinkold', id)
        data.append('linktitle', name)
        data.append('link', link) 
        if(icon) data.append('icon', icon) 
        ///
        let apiAction = action == 'add' ? '/api/addlink' : '/api/updatelink';
        Axios.post(Global.API_URL + apiAction,data).then((res) => {
            getListing(); 
            ProfileMenu.refrechLinks()
            resetForm()
        })
    }

    const resetForm = ()=>{
        document.getElementById("add-link").reset(); 
        setName('')
        setLink('')
        setIcon(null) 
        setAddingItem(false) 
        setAction('add');
        setId(null)
    }

    return(
        <div className="administration-headband">
            <h2>Mes liens</h2>
            <p>Permet de modifier les Mes liens.</p>

            {
                action === 'add' &&
                <div className="listing"> 
                    <Row> 
                        <Col xs={1}></Col> 
                        <Col xs={4}>Titre</Col> 
                        <Col xs={5}>Lien</Col> 
                        <Col xs={1}>Action</Col> 
                    </Row> 
                    {listing.map(item=>(
                        <Row> 
                            <Col xs={1}> 
                                <div className="item-image" style={{backgroundImage: `url(${Global.API_URL}/${item.linkicon})`}}></div>
                            </Col> 
                            <Col xs={4}>{ item.linktitle }</Col> 
                            <Col xs={5} className="link"><a href={ item.link } target="_blank">{ item.link }</a></Col> 
                            <Col xs={2}>
                                <div className="d-flex">
                                    <button className="edit-item" 
                                        onClick={()=>{
                                            setAction('edit'); 
                                            setId(item.id)
                                            setName(item.linktitle)
                                            setLink(item.link) 
                                        }}
                                    >
                                        <i className="fa fa-pencil"></i>
                                    </button>
                                    <button className="delete-item" onClick={()=>{deleteItem(item)}}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            </Col> 
                        </Row> 
                    ))}
                </div>
            } 

            <Form onSubmit={ onSubmit } id="add-link"> 
                { action == 'add' ? <h2>Nouveau lien</h2>  : <h2>Modifier le lien</h2> }  
                <Row> 
                    <Col>
                        <Form.Group>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control 
                            type="text" name="name" id="slogan" placeholder="" value={name} required
                            onChange={(e)=>{setName(e.target.value)}} /> 
                        </Form.Group> 
                    </Col> 
                </Row> 

                <Row> 
                    <Col>
                        <Form.Group>
                            <Form.Label>Lien</Form.Label>
                            <Form.Control 
                            type="text" name="link" id="link" placeholder="" value={link} required
                            onChange={(e)=>{setLink(e.target.value)}} /> 
                        </Form.Group> 
                    </Col> 
                </Row> 
                    
                <Row> 
                    <Col>
                        <Form.Group> 
                            <Form.File 
                            accept="image/*" 
                            onChange={(e)=>{ 
                                setIcon(e.target.files[0])
                            }}
                            name="logo" id="logo" label="Icone" />
                        </Form.Group> 
                    </Col> 
                </Row> 

                 
                <div className="submit-container">
                    <Button type="reset" onClick={()=>{resetForm()}}>
                        Annuler
                    </Button> 
                    &nbsp;&nbsp;&nbsp;
                    <Button variant="primary" type="submit" disabled={addingItem}>
                        { action == 'add' ? 'Ajouter'  : 'Modifier' }  
                        <BeatLoader color={"#aaa"} loading={addingItem} size="10"/>
                    </Button>
                </div>
            </Form>
        </div>
    )
}
export default Links