import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Global from '../../../inc/Global.js'

class Item extends Component {
    render() {
        const { 
            ID,
            Color,
            Invitation,
            Logo,
            //NBAdmin,
            NBUser,
            //Cover,
            Subject,
            Title,
            Type,
            UserEmail,
            //UserID,
            //Role,
            handleDelete
        } = this.props;
        
        const APIURL = Global.API_URL;
        
        return (
            <li className={"_ursl-listitem community-"+ID}>
                <div className="header-post">
                    <div className="rm-list">
                        <input type="checkbox" id={"community-"+ID} />
                        <label className="toggle" htmlFor={"community-"+ID}>...</label>
                        <div className="rm-list-item">
                            <ul>
                                {/* <li><Link to={{
                                    pathname : "/communautes/modifier",
                                    state : {
                                        id : ID,
                                        title : Title,
                                        subject : Subject,
                                        type : Type,
                                        color : Color,
                                        logo : Logo,
                                        cover : Cover
                                    }
                                }}>Modifier</Link></li> */}
                                <li><Link to="" id={ID} onClick={handleDelete}>Supprimer</Link></li>
                                <li><Link to={{
                                    pathname : `/communaute/${ID}/parametres`,
                                    state : {
                                        id : ID,
                                        logo : Logo,
                                        title : Title,
                                        type : Type,
                                        subject : Subject,
                                        nbuser : NBUser,
                                        invitation : Invitation,
                                        email : UserEmail,
                                        color : Color
                                    }
                                }}>Paramètres</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Logo ?
                                {backgroundImage: 'url('+ APIURL + '/' + Logo +')' } :
                                {backgroundColor: Color }
                            }>
                            {!Logo && Title.charAt(0) }
                        </figure>
                        <div className="">
                            <div className="username">{Title}</div>
                            <div className="p">
                                <span>{Subject}</span>
                            </div>

                            <div className="_cmitem-ft d-flex jc-space-b align-center">
                                <div className="">
                                    <span className="fa fa-users"></span><span className="_nburs">
                                        {Math.abs(NBUser) > 999 ? Math.sign(NBUser)*((Math.abs(NBUser)/1000).toFixed(1)) + 'k' : Math.sign(NBUser)*Math.abs(NBUser)}
                                        </span>&nbsp;&nbsp; 
                                        {Type=== "private" && 
                                            // <span className="fa fa-lock"></span>
                                            <span className="custom-icon custom-icon-private"></span>
                                        }
                                        {Type=== "secret" && 
                                            // <span className="fa fa-lock"></span>
                                            <span className="custom-icon custom-icon-secret"></span>
                                        }
                                    
                                </div>
                                <div className="">
                                    <span className="_nbof-members">
                                        {Math.abs(NBUser) > 999 ? Math.sign(NBUser)*((Math.abs(NBUser)/1000).toFixed(1)) + 'k' : Math.sign(NBUser)*Math.abs(NBUser)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </li>
        )
    }
}
export default Item;