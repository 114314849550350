import Axios from 'axios';
import React, { useEffect, useState } from 'react'

//Owl carousel
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; 
import 'react-owl-carousel2/src/owl.theme.default.css';

import Global from '../../inc/Global';
import VideoView from '../publications/template-parts/VideoView';

//CSS
import './_headband.scss'

const Headband = ()=>{

    //State
    const [listing, setListing] = useState([])   
    const options = {
        autoplay:true,
        autoplayTimeout:4000,
        autoplayHoverPause:true,
        loop:true,
        autoHeight: true,
        items: 1, 
        nav: false,
        dots: false
    };  

    useEffect(() => { 
        getListing()
    }, []); 

    const getListing = ()=>{
        Axios.get(Global.API_URL + "/bandeau").then((res) => {
            if( res.data.status ) setListing(res.data.result)  
        })
    }  
    if (listing.length ==0) return ''
    return (
        <div className="home-headband">
            {
                listing.length > 0 &&
                <OwlCarousel options={options} events={{}}> 
                    {listing.map(item=>(
                        <div> 
                            {
                                item.bandeau &&
                                <a href={item.link || '#'} target="_blank">
                                    <img src={`${Global.API_URL}/photo_directory/${item.bandeau}`} style={{borderRadius: 0}}  alt=""/>
                                </a>
                            }
                            {
                                item.video &&
                                <VideoView 
                                    videoUrl={Global.API_URL + "/video_directory/" + item.video} 
                                    poster={item.thumbs ? Global.API_URL + "/" + item.thumbs : ""} 
                                />
                            } 
                            {
                                item.text &&
                                <div className="slide-text" dangerouslySetInnerHTML={{__html: item.text}}></div>   
                            }  
                            
                        </div>
                    ))}
                </OwlCarousel>
            } 
        </div>
    )
}

export default Headband;