import React, { Component } from 'react'
import ProfileMenu from './ProfileMenu'
import {Link, NavLink} from 'react-router-dom'
import SearchForm from '../search-form/SearchForm'
import Notifications from './Notifications'
import ChatList from './Chat'
import BreakingNews from './BreakingNews'
import Global from '../../inc/Global'

import logo from '../../assets/img/logo.png'

var self

class Header extends Component {  

    constructor(){ 
        super() 
        self = this
        this.state = {
            appLogo: Global.APP_LOGO
        }
    }

    static setAppLogo(logo){ 
        if( self ){
            self.setState({
                appLogo: logo
            })
        }
    }

    render() {
        return (
            <>
                <header className="header" id="header">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b align-center header-flex">
                                <div className="brand">
                                    {
                                        this.state.appLogo &&
                                        <Link to="/" onClick={(e)=>{e.preventDefault();window.location.href = "/"}}>
                                            {/* <img src={this.state.appLogo} alt="Logo" />  */}
                                            <img src={logo} alt="Wearelinked logo" />
                                        </Link>
                                    }
                                    
                                    <SearchForm referer="header" />
                                </div> 

                                <div className="navmenu">
                                    <ul>
                                        <li>
                                            <NavLink exact to="/" onClick={(e)=>{e.preventDefault();window.location.href = "/"}} activeClassName="active">
                                                <i className="custom-icon custom-icon-home"></i>
                                                <span>Accueil</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  exact to="/communautes" activeClassName="active">
                                                <i className="custom-icon custom-icon-communautes"></i>
                                                <span>Communautés</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/utilisateurs" activeClassName="active">
                                                <i className="custom-icon custom-icon-user"></i>
                                                <span>Contacts</span>
                                            </NavLink>
                                        </li> 
                                    </ul>
                                </div>

                                <div className="dropdowns d-flex align-center">
                                    {/* <div className="d-flex jc-space-b align-center"> */}
                                    <ChatList />
                                    <Notifications />
                                    <div className="d-flex jc-space-fe align-center">
                                        <ProfileMenu />

                                        {/* <MainMenu /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <BreakingNews />
            </>
        )
    }
}

export default Header;
