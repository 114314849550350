import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Global from '../../../inc/Global'
import axios from 'axios'




class DeleteUser extends Component {
    constructor(props){
        super(props);
        if(typeof this.props.location.state === "undefined" ){
            return "";
        }else if(typeof this.props.location.state.id === "undefined"){
            return "";
        }

        const params = this.props.location.state;

        this.state = {
            id: params.id,
            name : params.name,
            lastname : params.lastname,
            email : params.email,
            job : params.job,
            phoneNum : params.phoneNum,
            photo : params.photo,
            deleteDefinitely : true,
            transferedData : {
                publications : false,
                medias : false,
                sondages : false,
                events : false,
            }
        }

        this.DeleteBtn = React.createRef();
        this.TransferBtn = React.createRef();
        this.handleDeleteDefinitely = this.handleDeleteDefinitely.bind(this);
        this.handleTransferData = this.handleTransferData.bind(this);
    }

    handleDeleteDefinitely(event){
        event.preventDefault();
        
        if(this.state.deleteDefinitely){
            this.DeleteBtn.current.classList.add('on');
            axios
            .delete(Global.API_URL+"/api/userdelete/"+this.state.id)
            .then((res) => {
                // console.log(res)
                this.DeleteBtn.current.classList.remove('on');
                this.props.history.push({
                    pathname: '/utilisateurs',
                    state: { addedUser: this.state.id }
                });
            })
            .catch((err) => {
                // console.log('Error : '+err.message)
                this.DeleteBtn.current.classList.remove('on');
            });
        }else{
            this.props.history.push('/utilisateurs')
        }
    }

    handleTransferData(event){
        event.preventDefault();
        if(this.state.deleteDefinitely){
            this.setState({
                deleteDefinitely : false
            })
        } else {
            // console.log("transfer");
        }
    }
    render() {
        const {id, name, lastname, email, job, phoneNum, photo} = this.state;
        const RandColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        return (
            <section className="content">
                <div className="wrap">
                    <div className="">
                        <div className={"user-"+id}>
                            <div className="_dlur-section p-10 bg-white text-center ">
                                <h3>Voulez-vous vraiment supprimer l'utilisateur ?</h3>
                                <figure 
                                    className="user-thumb" 
                                    style={photo ?
                                        {backgroundImage: 'url('+ Global.API_URL +"/"+  photo + ')'}
                                        : 
                                        {}
                                    } >
                                    {!photo && name.charAt(0)+lastname.charAt(0) }

                                </figure>
                                <div className="username">
                                    {name.toLowerCase()} {lastname.toLowerCase()}
                                </div>
                                <div className="p">
                                    <p>
                                        <span>{job}</span><br/>
                                        {phoneNum && 
                                            <span>Téléphone : {phoneNum}<br/></span>
                                        }
                                        {email && 
                                            <span>Email : {email}<br/></span>
                                        }
                                    </p>
                                </div>

                                {!this.state.deleteDefinitely && 
                                    <div className="advanced-settings">
                                        <div className="d-flex jc-space-b ">
                                            <div className="">
                                                <div className="param d-flex align-center">
                                                    <label htmlFor="_publications">Les publications</label>
                                                    <div className="radio-button">
                                                        <input type="checkbox" name="publications" id="_publications" />
                                                        <label htmlFor="_publications">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="param d-flex align-center">
                                                    <label htmlFor="_sondages">Les sondages</label>
                                                    <div className="radio-button">
                                                        <input type="checkbox" name="sondages" id="_sondages" />
                                                        <label htmlFor="_sondages">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="param d-flex align-center">
                                                    <label htmlFor="_medias">La médiathèque</label>
                                                    <div className="radio-button">
                                                        <input type="checkbox" name="medias" id="_medias" />
                                                        <label htmlFor="_medias">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="param d-flex align-center">
                                                    <label htmlFor="_events">Les évènements</label>
                                                    <div className="radio-button">
                                                        <input type="checkbox" name="events" id="_events" />
                                                        <label htmlFor="_events">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="d-flex jc-space-b m-t-10">
                                <Link ref={this.DeleteBtn} onClick={this.handleDeleteDefinitely} className="button wt lg">
                                    {this.state.deleteDefinitely ?
                                        <span><span className="fa fa-spin fa-spinner"></span> Supprimer définitivement</span>
                                        :
                                        "Annuler"
                                    }
                                </Link>
                                <Link ref={this.TransferBtn} onClick={this.handleTransferData} className="button lg   ">
                                    {this.state.deleteDefinitely ?
                                        <span><span className="fa fa-spin fa-spinner"></span> Transférer les données</span>
                                        :
                                        "Continuer"
                                    }
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default DeleteUser;