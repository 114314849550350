import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import '../../../assets/css/documents.css'
import Axios from 'axios'
import Global from '../../../inc/Global'
import configs from '../../../inc/helpers'
import InputField from '../../InputField'
import classnames from 'classnames'
import debounce from 'lodash.debounce'
import Lightbox from 'react-image-lightbox';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import BeatLoader from "react-spinners/BeatLoader";
import 'react-image-lightbox/style.css';


class Documents extends Component {
    constructor(props){
        super(props);
        //// console.log(this.props.folder, this.props.communityId)
        this.state = {
            folder : this.props.folder ? this.props.folder : null,
            communityId : this.props.communityId ? this.props.communityId : null,
            filesList : [],
            allFilesList : [],
            limit : 15,
            step : 10,
            startFrom : 0,
            loaded : false,
            foldername: "",
            addFolder : false,
            addFiles : false,
            errors : [],
            filesToAdd : [],
            filesToAddNames : [],
            addFilesError: "",
            uploadPercentage: 0,
            allLoaded : false,
            fileToDelete : false,
            folderToDelete : false,
            sortBy : "type",
            order : "asc",
            apiError: "",
            lightBox:{
                open: false
            }
        };

        this.AddFolderBtn = React.createRef();
        this.AddFilesBtn = React.createRef();
        this.InputFiles = React.createRef();
        this.searchB = React.createRef();
        this.DeleteFileBtn = React.createRef();
        this.DeleteFolderBtn = React.createRef();

        this.moveBtn = React.createRef(null);

        this.handleDocxChange = this.handleDocxChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSort = this.handleSort.bind(this);

        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.filesList.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }

    getPublicDirectoryData(prevProps){

        if( prevProps.folder === this.props.folder) return

        this.setState({
            loaded : false,
            defaultFolder: true
        })

        Axios
        .post(Global.API_URL + "/bibliogroupe", {
            email: this.props.auth.user.username,
            idgroup: this.state.communityId
        })
        .then((res) => {
            let filesAndFolders = [];
            if( res.data){
                res.data.forEach(file => {
                    file['type'] = 'file';
                    file['document'] = file.nomfichier1;
                    file['users'] = {
                        profile: {
                            nom: file.nom,
                            prenom: file.nom
                        }
                    }
                    filesAndFolders.push(file);
                }); 
                filesAndFolders.sort(function(a, b){
                    // default folder
                    return a.createdAt > b.createdAt
                });

                filesAndFolders.sort(function(a, b){
                    // default folder
                    return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
                })
            } 

            // console.log(filesAndFolders)

            if(this.state.limit >= filesAndFolders.length){
                this.setState({
                    allLoaded : true,
                    filesList : filesAndFolders,
                    allFilesList : filesAndFolders,
                    loaded : true
                })
            }else{
                this.setState({
                    allLoaded : false,
                    filesList : filesAndFolders,
                    allFilesList : filesAndFolders,
                    loaded : true
                })
            }
        })
        .catch((err) => {
            // console.log(err);
        });
    }

    componentDidUpdate(prevProps){ 
        console.log("ROOT CHANGED",prevProps); 
        if( this.props.default ){
            this.getPublicDirectoryData(prevProps);
            return
        } 
        console.log(prevProps.folder , this.props.folder);
        if(prevProps.folder !== this.props.folder){
            const folderId = this.props.folder ? this.props.folder : null;
            console.log('hey',folderId);
            this.setState({
                ...this.state,
                folder : folderId,
                loaded : false,
                defaultFolder: false,
            })

            const data = new FormData();
            data.append('email', this.props.auth.user.username);
            data.append('start', this.state.startFrom);
            data.append('nb', this.state.limit);
            data.append('idgroup', this.state.communityId);
            data.append('idoc', folderId);
            
            if(folderId){
                Axios
                .post(Global.API_URL + "/api/listbibliogroupes", data)
                .then((res) => {
                    let filesAndFolders = [];
                    if( res.data.Fichiers && res.data.Dossier ){
                        res.data.Fichiers.forEach(file => {
                            file['type'] = 'file';
                            file['users'] = {
                                profile: {
                                    nom: file.nom,
                                    prenom: file.prenom
                                }
                            }
                            filesAndFolders.push(file);
                        });
                        res.data.Dossier.forEach(folder => {
                            folder['type'] = 'folder';
                            filesAndFolders.push(folder);
                        });
                        filesAndFolders.sort(function(a, b){
                            // default folder
                            if(a.defaut) return a
                        if(b.defaut) return b
                            return a.createdAt > b.createdAt
                        });
    
                        filesAndFolders.sort(function(a, b){
                            // default folder
                            if(a.defaut) return a
                        if(b.defaut) return b
                            return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
                        })
                    } 

                    // console.log(filesAndFolders)

                    if(this.state.limit >= filesAndFolders.length){
                        console.log('hey',true);

                        this.setState({
                            allLoaded : true,
                            filesList : filesAndFolders,
                            allFilesList : filesAndFolders,
                            loaded : true
                        })
                    }else{
                        console.log('hey',false);
                        this.setState({
                            allLoaded : false,
                            filesList : filesAndFolders,
                            allFilesList : filesAndFolders,
                            loaded : true
                        })
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
            }else{
                Axios
                .get(Global.API_URL + "/doc/"+this.props.auth.user.username+"/"+this.state.communityId, data)
                .then((res) => {
                    let filesAndFolders = [];
                    
                    if( res.data ){
                        res.data.forEach(folder => {
                            folder['type'] = 'folder';
                            filesAndFolders.push(folder);
                        });
                    }
                    

                    Axios
                    .post(Global.API_URL + "/api/listfilegroupe", data)
                    .then((res) => {
                        res.data[0].bibliothèque.forEach(file => {
                            file['type'] = 'file'; 
                            filesAndFolders.push(file);
                        });

                        filesAndFolders.sort(function(a, b){
                            // default folder
                            if(a.defaut) return a
                        if(b.defaut) return b
                            return a.createdAt > b.createdAt
                        });
            
                        filesAndFolders.sort(function(a, b){
                            // default folder
                            if(a.defaut) return a
                        if(b.defaut) return b
                            return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
                        })
            
                        // console.log(filesAndFolders)
            
                        if(this.state.limit >= filesAndFolders.length){
                            this.setState({
                                allLoaded : true,
                                filesList : filesAndFolders,
                                allFilesList : filesAndFolders,
                                loaded : true
                            })
                        }else{
                            this.setState({
                                allLoaded : false,
                                filesList : filesAndFolders,
                                allFilesList : filesAndFolders,
                                loaded : true
                            })
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
                })
                .catch((err) => {
                    // console.log(err);
                });
            }
        }
    }

    componentDidMount(){

        if( this.state.default ){
            this.getPublicDirectoryData({folder: null});
            return
        }

        const data = new FormData();
        data.append('email', this.props.auth.user.username);
        data.append('start', this.state.startFrom);
        data.append('nb', this.state.limit);
        data.append('idgroup', this.state.communityId);
        data.append('idoc', this.state.folder);
        
        this.setState({
            loaded : false,
            defaultFolder: false
        })
        
        if(this.state.folder){
            console.log(this.state.folder)
            Axios
            .post(Global.API_URL + "/api/listbibliogroupes", data)
            .then((res) => {
                let filesAndFolders = [];
                res.data.Fichiers.forEach(file => {
                    file['type'] = 'file';
                    file['users'] = {
                        profile: {
                            nom: file.nom,
                            prenom: file.prenom
                        }
                    }
                    filesAndFolders.push(file);
                });
                res.data.Dossier.forEach(folder => {
                    folder['type'] = 'folder';
                    filesAndFolders.push(folder);
                });
                console.log(filesAndFolders)
                filesAndFolders.sort(function(a, b){
                    // default folder
                    if(a.defaut) return a
                        if(b.defaut) return b
                    return a.createdAt > b.createdAt
                });

                filesAndFolders.sort(function(a, b){
                    // default folder
                    if(a.defaut) return a
                        if(b.defaut) return b
                    return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
                })

                //// console.log(filesAndFolders)

                if(this.state.limit >= filesAndFolders.length){
                    this.setState({
                        allLoaded : true,
                        filesList : filesAndFolders,
                        allFilesList : filesAndFolders,
                        loaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false,
                        filesList : filesAndFolders,
                        allFilesList : filesAndFolders,
                        loaded : true
                    })
                }
            })
            .catch((err) => {
                // console.log(err);
            });
        }else{

            Axios
            .get(Global.API_URL + "/doc/"+this.props.auth.user.username+"/"+this.state.communityId, data)
            .then((res) => {
                let filesAndFolders = [];
                
                res.data.forEach(folder => {
                    folder['type'] = 'folder';
                    filesAndFolders.push(folder);
                });

                Axios
                .post(Global.API_URL + "/api/listfilegroupe", data)
                .then((res) => {
                    res.data[0].bibliothèque.forEach(file => {
                        file['type'] = 'file'; 
                        filesAndFolders.push(file); 
                    });

                    filesAndFolders.sort(function(a, b){
                        // default folder
                        if(a.defaut) return a
                        if(b.defaut) return b
                        return a.createdAt > b.createdAt
                    });
        
                    filesAndFolders.sort(function(a, b){
                        // default folder
                        if(a.defaut) return a
                        if(b.defaut) return b
                        return new Date(b.createdAt) <= new Date(a.createdAt) ? 1 : -1;
                    })
        
                    console.log(filesAndFolders)
        
                    if(this.state.limit >= filesAndFolders.length){
                        this.setState({
                            allLoaded : true,
                            filesList : filesAndFolders,
                            allFilesList : filesAndFolders,
                            loaded : true
                        })
                    }else{
                        this.setState({
                            allLoaded : false,
                            filesList : filesAndFolders,
                            allFilesList : filesAndFolders,
                            loaded : true
                        })
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });

                
            })
            .catch((err) => {
                // console.log(err);
            });
        }

        this.getAllDirectories();

    }
    handleInputChange (e){
        
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);
        
        const files = this.state.allFilesList.filter(item =>
            (item.document && item.document.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.nom && item.nom.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.nomauteur && item.nomauteur.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.prenomauteur && item.prenomauteur.toLowerCase().includes(event.target.value.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(event.target.value.toLowerCase()))
        );

        await this.setState({ 
            filesList: files,
        });
        


        if(this.state.limit >= this.state.filesList.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    cancel(e){
        e.preventDefault();
        this.setState({
            addFolder : false,
            addFiles : false,
            fileToDelete : false,
            folderToDelete : false,
            filesToAddNames : [],
            filesToAdd : []
        })
    }
    confirmFileDeletion(e){
        e.preventDefault();
        var btn = this.DeleteFileBtn;
        btn.current.classList.add('on');
        
        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('idfile', this.state.fileToDelete)
        data.append('isracine', this.state.folder ? true : false) 

        Axios
        .post(Global.API_URL+"/api/deletefile", data)
        .then((res) => {
            // console.log(res);
            btn.current.classList.remove('on');

            var files = [...this.state.filesList]; 
            var AllFiles = [...this.state.allFilesList];
    
            var currentFile = configs.getIndexWidthProperty(files, 'id', this.state.fileToDelete);
            var currentAllFile = configs.getIndexWidthProperty(AllFiles, 'id', this.state.fileToDelete);;
            
            

            files.splice(currentFile, 1)
            AllFiles.splice(currentAllFile, 1) 
            
            this.setState({
                fileToDelete : null,
                filesList : files,
                allFilesList : AllFiles
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
    }
    confirmFolderDeletion(e){
        e.preventDefault();
        var btn = this.DeleteFolderBtn;
        btn.current.classList.add('on');
        
        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('idossier', this.state.folderToDelete)
        data.append('idgroup', this.state.communityId)

        Axios
        .post(Global.API_URL+"/api/deletedossiergp", data)
        .then((res) => {
            
            btn.current.classList.remove('on');

            var files = [...this.state.filesList]; 
            var AllFiles = [...this.state.allFilesList];
            
            // console.log(files);//----------------------

            var currentFile = configs.getIndexWidthProperty(files, 'id', this.state.folderToDelete);
            var currentAllFile = configs.getIndexWidthProperty(AllFiles, 'id', this.state.folderToDelete);
            
            

            files.splice(currentFile, 1)
            AllFiles.splice(currentAllFile, 1)

            // console.log(files);//-----------------------

            this.setState({
                folderToDelete : false,
                filesList : files,
                allFilesList : AllFiles
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                folderToDelete : false
            });
        });
    }
    handleAddFolder(e){
        e.preventDefault();
        
        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('nomdossier', this.state.foldername)
        data.append('idoc', this.state.folder)
        data.append('idgroup', this.state.communityId)

        let btn = this.AddFolderBtn.current;
        btn.classList.add('on');
        Axios
        .post(Global.API_URL + '/api/createdosingroup', data)
        .then((res) => {
            // console.log(res.data.status)
            if(res.data.status === false){ 
                this.setState({
                    ...this.state,
                    apiError: res.data[0] || res.data.message
                }, ()=>{
                    setTimeout(()=>{
                        this.setState({
                            ...this.state,
                            apiError: ""
                        })
                    },5000)
                })
            }else{ 
                this.componentDidMount();

                this.setState({
                    addFolder : false
                })
            }
            btn.classList.remove('on');
        })
        .catch((err) => {
            btn.classList.remove('on');
            // console.log(err.message);
        })

        return;
    }
    handleAddFiles(e){
        e.preventDefault();
        if(this.state.filesToAdd.length <= 0){
            this.setState({
                addFilesError: "Il faut sélectionner au moin un fichier"
            })
            return;
        }
        if(this.state.filesToAdd.length > 5){
            return;
        }
        let btn = this.AddFilesBtn.current;
        btn.classList.add('on');
        
        this.setState({
            addFilesError : ""
        })

        const data = new FormData();
        data.append('email', this.props.auth.user.username)
        data.append('idgroup', this.state.communityId)
        
        if( this.state.filesToAdd.length > 0 ){
            this.state.filesToAdd.forEach((el, index) => {
                data.append('document['+index+']', el)
            });
        }else{
            // data.append('document', this.state.filesToAdd[0])
        }
        
        var API
        if(this.state.folder){
            data.append('idoc', this.state.folder);
            API = '/api/fileuploadindocgroup';
        }else{
            API = '/api/uploadfileingroup';
        }

        
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent < 100 ){
                    this.setState({ uploadPercentage: percent })
                }
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        Axios
        .post(Global.API_URL + API, data, options)
        .then((res) => {
            if(res.data[0].status){
                
                this.componentDidMount();
                
                this.setState({
                    ...this.state,
                    addFiles : false
                });
            }
            btn.classList.remove('on');
            
        })
        .catch((err) => {
            btn.classList.remove('on');
            // console.log(err.message);
        })

        return;
    }
    handleDocxChange(e){
        var filesToAddNames;
        var filesToAdd;

        
        filesToAddNames = this.state.filesToAddNames;
        filesToAdd = this.state.filesToAdd;
        

        for(var i = 0; i < e.target.files.length; i++) {
            var fileName = e.target.files[i].name;
            var ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()

            filesToAddNames.push(fileName);
            filesToAdd.push(e.target.files[i]);
        }

        this.setState({
            filesToAddNames: filesToAddNames,
            filesToAdd : filesToAdd
        })
        
        this.InputFiles.current.value = null;
    }
    removeFile(ev){
        var target = ev.target.getAttribute('data-target');
        var filesToAddNames = this.state.filesToAddNames;
        var filesToAdd = this.state.filesToAdd;

        filesToAddNames.splice(target, 1);
        filesToAdd.splice(target, 1);

        this.setState({
            filesToAddNames : filesToAddNames,
            filesToAdd : filesToAdd
        })
    }
    handleSort(a, b){
        let TypeA = "AAfolder";
        let TypeB = "AAfolder";
        let field1 = "";
        let field2 = ""; 

        // default folder
        if(a.defaut) return a
                        if(b.defaut) return b

        if(a.type === 'file'){
            TypeA = a.document ? a.document.substring(a.document.lastIndexOf(".")+1).toLowerCase() : "txt";
        }

        if(b.type === 'file'){
            TypeB = b.document ? b.document.substring(b.document.lastIndexOf(".")+1).toLowerCase() : "txt";
        }

        
        if(this.state.sortBy === "name"){

            if(a.nom){
                field1 = a.nom.toLowerCase();
            }else if(a.document){
                field1 = a.document.toLowerCase();
            }
            
            if(b.nom){
                field2 = b.nom.toLowerCase();
            }else if(b.document){
                field2 = b.document.toLowerCase();
            }
        }else if(this.state.sortBy === "date"){
            
            field1 = new Date(a.createdAt);
            field2 = new Date(b.createdAt);
            
        }else{
            field1 = TypeA;
            field2 = TypeB
        }

        if(this.state.sortBy === "type"){
            
            if(this.state.order === "asc"){
                if( a.type == b.type ){
                    return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
                }else{
                    return field1 > field2  ? 1 : -1
                } 
            }else{
                if( a.type == b.type ){
                    return new Date(b.createdAt) < new Date(a.createdAt) ? 1 : -1
                }else{
                    return field1 < field2  ? 1 : -1
                } 
            }

        }else{
            if(this.state.order === "asc"){ 
                return field1 > field2 ? 1 : -1;
            }else{
                return field1 <= field2 ? 1 : -1;
            }
        }  
    } 

    getAllDirectories(){
        Axios
        .post(Global.API_URL + "/api/listdossiersingroup", { 
            idgroup: this.state.communityId,
            start: 0,
            nb: 9999999999999
        }).then((res) => {
            if(res.data.status){
                this.setState({
                    treeStructure: res.data.Dossier
                })
            }
        })
    }

    renameElement(newName, type, id){
        Axios
        .post(Global.API_URL + "/api/renamefileordocingroup", {
            idfile: type == 'file' ? id : null,
            newname: newName,
            idgroup: this.state.communityId,
            idoc: type == 'folder' ? id : null,
        })
        .then((res) => {})
    }

    moveElement(){
        this.moveBtn.current.classList.add('on');
        if(this.state.moveFolder.type == 'folder'){
            Axios
            .post(Global.API_URL + "/api/switchdoc", {
                idocdeplace: this.state.moveFolder.folderId,
                idocdestination: this.state.moveFolder.destinationId,
                idgroup: this.state.communityId, 
            })
            .then((res) => {
                this.setState({
                    moveFolder: false
                },()=>{
                    this.componentDidMount();
                })
            })
        }else{
            Axios
            .post(Global.API_URL + "/api/switchfile", {
                idfile: this.state.moveFolder.fileId,
                idocdestination: this.state.moveFolder.destinationId,
                idgroup: this.state.communityId, 
            })
            .then((res) => {
                this.setState({
                    moveFolder: false
                },()=>{
                    this.componentDidMount();
                })
            })
        } 
    }

    render() {
        const TreeStructure = (props)=>{ 
            return(
                <ul>
                    {props.folders.map(folder=>{
                        if(this.state.moveFolder.type === 'file' || (this.state.moveFolder.type === 'folder' && this.state.moveFolder.folderId != folder.idossier) ){
                            return(
                                <li>
                                    <span 
                                        className={`folder-name ${this.state.moveFolder.destinationId === folder.idossier ? 'active' : ''}`}
                                        onClick={()=>{ 
                                            this.setState({
                                                moveFolder: {
                                                    ...this.state.moveFolder,
                                                    destinationId: folder.idossier
                                                }
                                            })
                                        }}>
                                        <i className="custom-icon custom-icon-folder"></i> 
                                        {folder.nom}
                                    </span>
                                    {
                                        folder.child &&
                                        <TreeStructure folders={Object.values(folder.child)} />
                                    }
                                </li>
                            )
                        } 
                    })}
                </ul>
            )
        }
        
        return (
            <div className="content">
                <div className="wrap">
                    <div className="documents">
                    <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3 className="h3"> 
                                    <center>
                                        <Link to={`/communaute/${this.state.communityId}`}>
                                            <span className="fa fa-long-arrow-left"></span>
                                        </Link> 
                                        Documents
                                    </center>
                                </h3> 
                                {
                                    ( !configs.isAdmin() && this.props.communityData.userconnecterole !== 'ROLE_ADMIN' ) &&
                                    <div className="documents-action-buttons"> 
                                        <Link to="/" className="button filter" onClick={(e) => {e.preventDefault(); this.setState({showFilter : !this.state.showFilter})}}>
                                            <i className="fa fa-search"></i>
                                            {/* <PlusCircle /> */}
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>

                        {
                            (configs.isAdmin() || this.props.communityData.userconnecterole === 'ROLE_ADMIN' ) &&
                            <div className="documents-action-buttons"> 
                                <Link to="/" className="button" onClick={(e) => {e.preventDefault(); this.setState({addFolder : true, foldername: ""})}}>
                                    Créer un dossier  
                                    {/* <PlusCircle /> */}
                                </Link>
                                <Link to="/" className="button" onClick={(e) => {e.preventDefault(); this.setState({addFiles : true, filesToAddNames: [], filesToAdd: [], uploadPercentage: 0});}} title="Maximum de 5 fichiers à télécharger">
                                    Ajouter un document 
                                    {/* <PlusCircle /> */}
                                </Link>
                                <Link to="/" className="button btn-filter" onClick={(e) => {e.preventDefault(); this.setState({showFilter : !this.state.showFilter})}}>
                                    <i className="fa fa-search"></i> 
                                    {/* <PlusCircle /> */}
                                </Link>
                            </div>
                        }

                        {
                            this.state.showFilter &&
                            <div className="_srs-users">
                                <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Recherche"/>
                                <Link to="" onClick={(e) => {
                                        e.preventDefault();
                                        this.searchB.current.setAttribute('val', ""); 
                                        this.searchB.current.value = ""; 
                                        this.setState({filesList : this.state.allFilesList
                                    })}} className="button">Annuler</Link>
                            </div>
                        }

                        {
                            this.state.showFilter &&
                            <div className="_Filter bg-white p-20 brs-10 d-flex jc-space-b m-b-10">
                                <div className="d-flex jc-space-b align-center">
                                    <span>
                                        Ordre : 
                                    </span>
                                    <ul className="d-flex jc-space-b">
                                        <li>
                                            <Link to="#" onClick={(e)=> {e.preventDefault(); this.setState({order : "asc"})}} className={this.state.order === "asc" ? " active " : "" }>
                                                <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={(e)=> {e.preventDefault(); this.setState({order : "desc"})}} className={this.state.order === "desc" ? " active " : "" }>
                                                <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-flex jc-space-b align-center">
                                    <span>
                                        Trier par : 
                                    </span>
                                    <ul className="d-flex jc-space-b">
                                        <li>
                                            <Link to="#" onClick={(e)=> {e.preventDefault(); this.setState({sortBy : "name"})}} className={this.state.sortBy === "name" ? " active " : "" }>
                                                Nom
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={(e)=> {e.preventDefault(); this.setState({sortBy : "date"})}} className={this.state.sortBy === "date" ? " active " : "" }>
                                                Date
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={(e)=> {e.preventDefault(); this.setState({sortBy : "type"})}} className={this.state.sortBy === "type" ? " active " : "" }>
                                                Type
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }   
                         
                            <div className="bg-white p-20 brs-10">
                                <div className="_docs-list">
                                    <ul> 

                                        {
                                            (this.state.folder || this.state.defaultFolder ) && this.state.loaded &&
                                            <li className={`doc-item back-to-parent`} onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}> 
                                                <div className="doc-info">
                                                    <div className="d-flex align-center">
                                                        <figure className="doc-icon">
                                                            <i className="custom-icon custom-icon-parent-folder default-folder"></i>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </li>
                                        }

                                        {
                                            (this.state.loaded && this.state.filesList.length > 0) &&
                                            <div>
                                        
                                                {this.state.filesList
                                                .sort(function(a, b){
                                                    return this.handleSort(a, b)
                                                }.bind(this))
                                                .slice(0, this.state.limit)
                                                .map(function(el, index){
                                                    if(el.type === "folder"){ 
                                                        return (
                                                            <li key={'folder-' + index} className={`doc-item ${el.defaut ? 'defaut-folder' : ''}`} >
                                                                <div className="rm-list">
                                                                    <input type="checkbox" id={"fld-" + el.id} />  
                                                                    {
                                                                        (configs.isAdmin() || this.props.communityData.userconnecterole === 'ROLE_ADMIN' ) && !el.defaut &&
                                                                        <label className="toggle" htmlFor={"fld-" + el.id}>...</label>
                                                                    }
                                                                    {
                                                                        (configs.isAdmin() || this.props.communityData.userconnecterole === 'ROLE_ADMIN' ) &&
                                                                        <div className="rm-list-item">
                                                                            {
                                                                                !el.defaut &&
                                                                                <ul> 
                                                                                    <li>
                                                                                        <Link to="/" onClick={(e) => {
                                                                                            e.preventDefault(); 
                                                                                            el.showRenameForm = true; 
                                                                                            el.newFolderName = el.nom; 
                                                                                            this.setState({}) 
                                                                                        }}>
                                                                                            Renommer
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link to="/" onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    moveFolder:{
                                                                                                        type: 'folder',
                                                                                                        folderId: el.id,
                                                                                                        destinationId: false
                                                                                                    }
                                                                                                }) 
                                                                                            }}>
                                                                                            Déplacer
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link to="/" onClick={(e) => {e.preventDefault(); this.setState({folderToDelete : el.id})}}>
                                                                                            Supprimer
                                                                                        </Link> 
                                                                                    </li>
                                                                                </ul>
                                                                            }  
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="doc-info">
                                                                    <div className="d-flex">
                                                                        <figure className="doc-icon">
                                                                            <Link to={"/communaute/" + this.state.communityId + "/mediatheque/" + el.id+(el.defaut ? '/default' : '')}>
                                                                                {/* <img src={configs.GetImage(el.defaut ? 'icon-default-folder.png' : 'icon-folder.png')} alt="" /> */}
                                                                                <i className={`custom-icon custom-icon-folder ${el.defaut ? 'default-folder' : ''}`}></i>
                                                                            </Link>
                                                                        </figure>
                                                                        <div className="">
                                                                            {
                                                                                el.showRenameForm ?
                                                                                    <div className="rename-form d-flex">
                                                                                        <div>
                                                                                            <input autoFocus={true} className="form-controle" value={el.newFolderName} onChange={(e)=>{el.newFolderName = e.target.value; this.setState({})}} />
                                                                                        </div> 
                                                                                        <div className="d-flex">
                                                                                            <button onClick={(e) => {
                                                                                                el.showRenameForm = false; this.setState({}) 
                                                                                            }} className="button cancel">Annuler</button>

                                                                                            <button onClick={(e) => {
                                                                                                el.showRenameForm = false; 
                                                                                                el.nom = el.newFolderName; 
                                                                                                this.setState({})
                                                                                                this.renameElement(el.newFolderName, 'folder', el.id)
                                                                                            }} className="button rename">Renommer</button>
                                                                                        </div>
                                                                                    </div>
                                                                                :
                                                                                    <>
                                                                                        <span className="doc-name">
                                                                                            <Link to={"/communaute/" + this.state.communityId + "/mediatheque/" + el.id+(el.defaut ? '/default' : '')}>
                                                                                                {el.nom}
                                                                                            </Link>
                                                                                        </span><br />
                                                                                    </>
                                                                            }   
                                                                            {
                                                                                (el.nomauteur || el.prenomauteur) &&
                                                                                <span className="doc-author">{el.prenomauteur.toLowerCase()} {el.nomauteur.toLowerCase()}<br /></span>
                                                                            } 
                                                                            <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }else{
                                                        const ext = el.document ? el.document.substring(el.document.lastIndexOf(".")+1).toLowerCase() : null;
                                                        const fileType = configs.GetFileIcon(ext,true)
                                                        return (
                                                            <li key={"file-" + index} className="doc-item">
                                                                <div className="rm-list">
                                                                    <input type="checkbox" id={"fl-" + el.id} />
                                                                    {
                                                                        (configs.isAdmin() || this.props.communityData.userconnecterole === 'ROLE_ADMIN' ) &&
                                                                        <label className="toggle" htmlFor={"fl-" + el.id}>...</label>
                                                                    }
                                                                    {
                                                                        (configs.isAdmin() || this.props.communityData.userconnecterole === 'ROLE_ADMIN' ) &&
                                                                        <div className="rm-list-item">
                                                                            <ul>
                                                                                {
                                                                                    !this.props.default &&
                                                                                    <>
                                                                                        <li>
                                                                                            <Link to="/" onClick={(e) => {
                                                                                                e.preventDefault(); 
                                                                                                el.showRenameForm = true; 
                                                                                                el.newFileName = el.documentreelname; 
                                                                                                this.setState({filesList:[...this.state.filesList]}) 
                                                                                            }}>
                                                                                                Renommer
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to="/" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.setState({
                                                                                                        moveFolder:{
                                                                                                            type: 'file',
                                                                                                            fileId: el.id,
                                                                                                            destinationId: false
                                                                                                        }
                                                                                                    }) 
                                                                                                }}>
                                                                                                Déplacer
                                                                                            </Link>
                                                                                        </li>
                                                                                    </>
                                                                                }
                                                                                
                                                                                <li>
                                                                                    <Link to="/" onClick={(e) => {e.preventDefault(); this.setState({fileToDelete : el.id})}} >
                                                                                        Supprimer
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                    
                                                                </div>
                                                                {
                                                                    (fileType == 'video' || fileType == 'image') &&
                                                                    <div className="doc-info">
                                                                        <div className="d-flex align-center">
                                                                            <figure className="doc-icon">
                                                                                <div  onClick={()=>{this.setState({lightBox:{open: true, type: fileType, url: Global.API_URL +"/" + el.document}})}}>
                                                                                    <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={el.document} />         
                                                                                </div>
                                                                            </figure>
                                                                            <div className="">
                                                                                {
                                                                                    el.showRenameForm ?
                                                                                        <div className="rename-form d-flex">
                                                                                            <div>
                                                                                                <input className="form-controle" value={el.newFileName} onChange={(e)=>{el.newFileName = e.target.value; this.setState({})}} />
                                                                                            </div> 
                                                                                            <div className="d-flex">
                                                                                                <button onClick={(e) => {
                                                                                                    el.showRenameForm = false; this.setState({}) 
                                                                                                }} className="button cancel">Annuler</button>

                                                                                                <button onClick={(e) => {
                                                                                                    el.showRenameForm = false; 
                                                                                                    el.documentreelname = el.newFileName; 
                                                                                                    this.setState({})
                                                                                                    this.renameElement(el.newFileName, 'file', el.id)
                                                                                                }} className="button rename">Renommer</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    :
                                                                                        <>
                                                                                            <span className="doc-name" onClick={()=>{this.setState({lightBox:{open: true, type: fileType, url: Global.API_URL +"/" + el.document}})}}>
                                                                                                {el.documentreelname}
                                                                                            </span><br />
                                                                                        </>
                                                                                } 
                                                                                
                                                                                <span className="doc-author">{el?.users?.profile?.prenom?.toLowerCase()} {el?.users?.profile?.nom?.toLowerCase()}<br /></span>
                                                                                <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                }  
                                                                {
                                                                    fileType == 'file' &&
                                                                    <div className="doc-info">
                                                                        <div className="d-flex align-center">
                                                                            <figure className="doc-icon">
                                                                                <a href={Global.API_URL +"/" + el.document} target="_blank">
                                                                                    <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={el.document} />         
                                                                                </a>
                                                                            </figure>
                                                                            <div className="">
                                                                                {
                                                                                    el.showRenameForm ?
                                                                                        <div className="rename-form d-flex">
                                                                                            <div>
                                                                                                <input className="form-controle" value={el.newFileName} onChange={(e)=>{el.newFileName = e.target.value; this.setState({})}} />
                                                                                            </div> 
                                                                                            <div className="d-flex">
                                                                                                <button onClick={(e) => {
                                                                                                    el.showRenameForm = false; this.setState({}) 
                                                                                                }} className="button cancel">Annuler</button>

                                                                                                <button onClick={(e) => {
                                                                                                    el.showRenameForm = false; 
                                                                                                    el.documentreelname = el.newFileName; 
                                                                                                    this.setState({})
                                                                                                    this.renameElement(el.newFileName, 'file', el.id)
                                                                                                }} className="button rename">Renommer</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    :
                                                                                        <>
                                                                                            <span className="doc-name"><a target="_blank" href={Global.API_URL +"/" + el.document} download>
                                                                                                {
                                                                                                    el.documentreelname ?
                                                                                                    <>
                                                                                                        {el.documentreelname}
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                    
                                                                                                        {
                                                                                                            el.nomfichier1reelname ?
                                                                                                            <>
                                                                                                                {el.nomfichier1reelname}
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    el.nomfichier1 &&
                                                                                                                    <>
                                                                                                                        {el.nomfichier1}
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                }    
                                                                                            </a></span><br />
                                                                                        </>
                                                                                }  
                                                                                <span className="doc-author">{el?.users?.profile?.prenom?.toLowerCase()} {el?.users?.profile?.nom?.toLowerCase()}<br /></span>
                                                                                <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                } 
                                                            </li>
                                                        )
                                                    }
                                                }.bind(this))}
                                                
                                            </div>
                                        }
                                    </ul>
                                </div>
                            </div> 

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de fichiers</span>
                                :
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            }
                        </div>


                        {this.state.addFolder && 
                            <div className="_modal AddFolder" id="AddFolder">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Entrer le nom de nouveau dossier </h2>
                                        </div>
                                        <div className="_modal-body f-wrap">
                                            <div className="p-10 w100">
                                                <div className="">
                                                    <InputField 
                                                        type="text" 
                                                        id="_foldername" 
                                                        name="foldername" 
                                                        label="" 
                                                        fixedLabel="false" 
                                                        classInput=""
                                                        placeholder="Nom de dossier"
                                                        classContainer={classnames({
                                                            " hasError ": this.state.errors.foldername,
                                                        })} 
                                                        value={this.state.foldername} 
                                                        oninput={this.handleInputChange} 
                                                        errorMsg={classnames({
                                                            "l'email est obligatoire et il doit respecter ce format example@email.com": this.state.errors.email,
                                                        })} />
                                                </div>
                                                {
                                                    this.state.apiError &&
                                                    <div style={{color: 'red'}}>
                                                        { this.state.apiError }
                                                    </div>
                                                } 
                                            </div>
                                            <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                                <button onClick={this.handleAddFolder.bind(this)} ref={this.AddFolderBtn} className="button lg">
                                                    <span className="fa fa-spin fa-spinner"></span> Ajouter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.fileToDelete && 
                            <div className="_modal deleteFile confirmation" id="deleteFile">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir supprimer cette fichier&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                            <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                                <button onClick={this.confirmFileDeletion.bind(this)} ref={this.DeleteFileBtn} className="button lg">
                                                    <span className="fa fa-spin fa-spinner"></span> Supprimer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.folderToDelete && 
                            <div className="_modal deleteFile confirmation" id="deleteFile">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir supprimer cette dossier&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmFolderDeletion.bind(this)} ref={this.DeleteFolderBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Supprimer
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.addFiles && 
                            <div className="_modal addFiles" id="addFiles">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Sélectionnez les fichiers à ajouter</h2>
                                        </div>
                                        <div className="_modal-body f-wrap">
                                            <div className="p-10 w100">
                                                {this.state.filesToAdd.length > 5 && 
                                                    <div>
                                                        <div className="_alert _alert-danger">
                                                            Il faut choisir maximum 5 fichiers
                                                        </div>
                                                        <br />
                                                    </div>
                                                }
                                                {this.state.filesToAddNames.length !== 0 &&
                                                    <div className="fileList">
                                                        {this.state.filesToAddNames.map(function(file, ind){
                                                            const ext = file ? file.substring(file.lastIndexOf(".")+1).toLowerCase() : null;
                                                            return (
                                                                <span key={"docx-"+ind}> 
                                                                    <span className="remove" >
                                                                        <i  data-target={ind} onClick={this.removeFile.bind(this)}>&times;</i>&nbsp;
                                                                        <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={file} /> 
                                                                        &nbsp;&nbsp;{file}
                                                                    </span>

                                                                </span>
                                                            )
                                                        }.bind(this))}
                                                    </div>
                                                }

                                                <div className="addNewFiles">
                                                    <label htmlFor="_addFiles" className="button lg">
                                                        <span className="">
                                                            <i className="fa fa-plus _xlv"></i>
                                                        </span>&nbsp;&nbsp; {this.state.filesToAdd.length === 0 ? "Télécharger des fichiers" : "Ajouter d'autres fichiers"}
                                                    </label>
                                                    <input ref={this.InputFiles} type="file" className="_addFiles" onChange={this.handleDocxChange} id="_addFiles" multiple />
                                                </div>
                                                
                                                
                                                <div className="p-10">
                                                    <small>
                                                        * Maximum de 5 fichiers à télécharger
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            {this.state.uploadPercentage !== 0 && 
                                                <div className="loaderProgress">
                                                    <div className="progressBar">
                                                        <span style={{width: this.state.uploadPercentage + "%"}}></span>
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                                <button onClick={this.handleAddFiles.bind(this)} ref={this.AddFilesBtn} className="button lg">
                                                    <span className="fa fa-spin fa-spinner"></span> Ajouter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.lightBox.type === 'image' && this.state.lightBox.open &&
                    <Lightbox
                        mainSrc={this.state.lightBox.url} 
                        onCloseRequest={()=>{this.setState({lightBox:{open: false}})}}
                        reactModalStyle={{zIndex:100000}}  
                        animationDisabled={true}
                    />
                } 
                {   this.state.lightBox.type === 'video' && this.state.lightBox.open &&
                    <div className="ReactImageVideoLightbox">
                        <ReactImageVideoLightbox
                            data={[{ url: this.state.lightBox.url, type: 'video', altTag: '' }]}
                            startIndex={0}
                            showResourceCount={false}
                            onCloseCallback={()=>{this.setState({lightBox:{open: false}})}} 
                        />
                    </div>
                }

                {
                    this.state.moveFolder &&
                    <div className="_modal moveFolder">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="content">
                                <div className="_modal-content"> 
                                    <div className="_modal-header">
                                        <h2>Choisir le dossier de destination</h2>
                                    </div>
                                    <div className="_modal-body">
                                        <ul>
                                            <li>
                                                <span 
                                                    className={`folder-name ${this.state.moveFolder.destinationId === null ? 'active' : ''}`}
                                                    onClick={()=>{ 
                                                        this.setState({
                                                            moveFolder: {
                                                                ...this.state.moveFolder,
                                                                destinationId: null
                                                            }
                                                        })
                                                    }}>
                                                    <i className="custom-icon custom-icon-folder"></i>
                                                    Racine
                                                </span>
                                                {
                                                    this.state.treeStructure &&
                                                    <TreeStructure folders={this.state.treeStructure} />
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="_modal-footer">
                                        <div className="d-flex jc-space-b m-t-10 ">
                                            <button onClick={()=>{this.setState({moveFolder: null})}} className="button wt lg">Annuler</button>
                                            <button onClick={()=>{this.moveElement()}} ref={this.moveBtn} type="button" className="button lg" disabled={this.state.moveFolder.destinationId === false}>
                                                <span className="fa fa-spin fa-spinner"></span>  Déplacer 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

Documents.propTypes = {
    auth : PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(Documents));