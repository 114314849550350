import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import configs from '../inc/helpers';
import logo from '../assets/img/white-logo.png';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../inc/ImageLib';
import jwt_decode from "jwt-decode";
import axios from "axios";
import Global from '../inc/Global';
import PropTypes from "prop-types";
import { connect } from "react-redux";

const imageMaxSize = 5242880 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class UploadPhoto extends Component {
    constructor(props){
        super(props);
        this.state = {
            imgSrc : null,
            dragIsActive : false,
            imgSrcExt: null,
            profilSrc : null,
            imgFile : null,
            orgWidthAspect: null,
            crop :{ 
                aspect: 1/1,
                unit: '%'
            }
        };

        this.canvasRef = React.createRef(null);
    }
    componentDidMount(){
        const data = JSON.parse(localStorage.getItem('jwtUd'));
        // console.log(data)

        if(data.profil){
            this.setState({
                profilSrc : data.profil !== '0' ? Global.API_URL +"/"+ data.profil : null
            })
        }
    }
    handleOnCropChange(crop){
        crop.aspect = 1;
        this.setState({crop:crop});
    }
    handleSaveClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state
        if (imgSrc) {
        
            const canvasRef = this.canvasRef.current
        
            const {imgSrcExt} =  this.state
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            const decode = jwt_decode(localStorage.jwtToken)
            const myFilename = "profile-"+ decode.username +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            
            await this.setState({
                profilSrc : imageData64,
                imgFile : myNewCroppedFile,
                imgSrc : null,
            }); 
            // console.log(this.state.imgFile);
           
        }
    }

    handleAcceptButton = (event) => {
        event.preventDefault();
        var $target = event.target;

        if( !this.state.imgFile ){
            this.props.history.push('/terminer-inscription/reinitialiser-motdepasse');
            return;
        }
        

        var data = new FormData();
        data.append('image', this.state.imgFile);

        axios
        .post(Global.API_URL+"/api/profil/image", 
            data, 
            { headers: {
                'content-type': 'multipart/form-data'
                }
            }
        )
        .then((res) => {
            // console.log(res)
            $target.classList.remove('on');
            this.props.history.push('/terminer-inscription/reinitialiser-motdepasse');
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            $target.classList.remove('on');
        });
    }
    handleOnCropComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasRef.current
        const {imgSrc}  = this.state
        
        const newCrop = {
          axpect : 1,
          x: (crop.x * this.state.orgWidthAspect),
          y: (crop.y * this.state.orgWidthAspect),
          width: (crop.width * this.state.orgWidthAspect),
          height: (crop.height * this.state.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    
    handleImageLoaded = async image => {
        await this.setState({
            orgWidthAspect: image.naturalWidth / image.width
        });

        
        if(image.width > image.height){
            await this.setState({ crop: {width: (image.height - 50), height: (image.height - 50), x :25, y: 25 } });
        }else{
            await this.setState({ crop: {width: (image.width - 50), height: (image.width - 50), x :25, y: 25 } });
        }
        
        const canvasRef = this.canvasRef.current
        const {imgSrc}  = this.state
        
        const newCrop = {
            axpect : 1,
            x: (this.state.crop.x * this.state.orgWidthAspect),
            y: (this.state.crop.y * this.state.orgWidthAspect),
            width: (this.state.crop.width * this.state.orgWidthAspect),
            height: (this.state.crop.height * this.state.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        return false; // Return false when setting crop state in here.
    };
    
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.file.type
            const currentFileSize = currentFile.file.size
            
            if(currentFileSize > imageMaxSize) {
                alert("Ce fichier n'est pas autorisé, " + currentFileSize + " octets est trop grand")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("Ce fichier n'est pas autorisé. Seules les images sont autorisées.")
                return false
            }
            return true
        }
    }
    
    handleOndrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", ()=>{
                
                const myResult = myFileItemReader.result
                
                this.setState({
                    imgSrc: myResult,
                    imgSrcExt: extractImageFileExtensionFromBase64(myResult)
                })

                
            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEnter = (DragEvent) => {
        this.setState({dragIsActive : true});
    }
    handleOnDragLeave = (DragEvent) => {
        this.setState({dragIsActive : false});
    }
    
    
    render() {
        const { dragIsActive} = this.state;
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <div className="_cnu-logo">
                                                <img src={Global.APP_LOGO} alt="" />
                                            </div>
                                            <h3>Compléter mon profil</h3>
                                            <div className={`bg-white-transparent p-10 brs-10 ${dragIsActive ? 'dargActive' : ''}`}>
                                                <div className="tab _p-photo d-flex align-center jc-center">
                                                    <div className="select-profil">
                                                        {this.state.imgSrc && 
                                                            <div className="_modal" id="CropImageModal">
                                                                <div className="_overlay"></div>
                                                                <div className="_modal-wrap">
                                                                    <div className="_modal-content">
                                                                        <div className="_modal-header">
                                                                            <h2>Recadrer l'image</h2>
                                                                        </div>
                                                                        <div className="_modal-body">
                                                                            <div className="d-flex f-wrap"> 
                                                                                <div className="rightSidebar">
                                                                                    <canvas ref={this.canvasRef}></canvas>
                                                                                </div>
                                                                                <div className="_modal-body-wrap crop">
                                                                                    <ReactCrop 
                                                                                        src={this.state.imgSrc} 
                                                                                        crop={this.state.crop} 
                                                                                        onChange={this.handleOnCropChange.bind(this)} 
                                                                                        onComplete={this.handleOnCropComplete} 
                                                                                        onImageLoaded={this.handleImageLoaded}
                                                                                        />
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="_modal-footer text-right">
                                                                            <span onClick={this.handleSaveClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                                        </div>
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                        <Dropzone 
                                                            accept={acceptedFileTypes} 
                                                            onDrop={this.handleOndrop} 
                                                            multiple={false} 
                                                            maxSize={imageMaxSize}
                                                            onDragEnter={this.handleOnDragEnter}
                                                            onDragLeave={this.handleOnDragLeave} >
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                                
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <span  className="img" 
                                                                            style={this.state.profilSrc ? {backgroundImage: 'url('+this.state.profilSrc+')' } : {} }>
                                                                        {
                                                                            !this.state.profilSrc &&
                                                                            <span className="custom-icon custom-icon-user"></span>
                                                                        }
                                                                        
                                                                    </span>
                                                                    <label className="label">Charger une photo</label>
                                                                </div>
                                                            </section>
                                                        )}
                                                        </Dropzone>
                                                                
                                                        
                                                        {/* <input type="file" className="ph-profil" id="ph-profil" name="ph-profil" onChange={this.onImageSelected.bind(this)} /> */}
                                                    </div>
                                                </div>
                                                <div className="_cnu-footer-tabs footer-fixed m-t-10">
                                                    <div className="d-flex jc-space-b">
                                                        <Link to="/terminer-inscription/profil" className="button lg wt icon">
                                                            <i className="fa fa-chevron-left"></i> ANNULER
                                                        </Link>
                                                        <Link to="" onClick={this.handleAcceptButton} className="button lg icon">
                                                            SUIVANT <i className="fa fa-chevron-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UploadPhoto.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(UploadPhoto);