import Axios from 'axios';
import React, { useEffect, useState } from 'react'  

import ChatList from '../../header/Chat'
import Global from '../../../inc/Global';




const CustomPopHoverUser = ({user})=> {
   
    return (
        <div className="user-community-popClick" onMouseLeave={(e)=>{e.target.classList.add('hide')}}>
            <div className="d-flex">
            <figure 
                style={(user.images && user.images.image) ?
                    {backgroundImage: 'url('+ Global.API_URL +"/"+ user.images.image + ')'}
                    : 
                    {backgroundColor: 'var(--primary-color)'}
                }>
                {!(user.images && user.images.image) && 
                    <span>{user?.profile?.nom?.charAt(0)+user.profile?.prenom?.charAt(0)}</span>
                } 
            </figure> 
            <div>
                <h3 className="user-name">{user.profile.prenom.toLowerCase()} {user.profile.nom.toLowerCase()}</h3>
                <div className="user-infos">
                    <ul>
                        {
                            user.profile.fonction &&
                            <li><i className="fa fa-briefcase"></i> {user.profile.fonction}</li>
                        }
                        {
                            user.email &&
                            <li><i className="fa fa-envelope"></i> <a target="_blank" href={`mailto:${user.email}`}>{user.email}</a></li>
                        } 
                        {
                            user.profile.telephone &&
                            <li><i className="fa fa-phone"></i> <a target="_blank" href={`tel:${user.profile.telephone}`}>{user.profile.telephone}</a></li>
                        } 
                    </ul> 

                    <button className="start-chat" onClick={(e)=>{
                        ChatList.startChatByUser(e, user)
                        user.popopen = false;
                    }}><i className="fa fa-comments"></i></button>   
                </div>
            </div>
        </div>
        </div>
    
    )
}
export default CustomPopHoverUser
