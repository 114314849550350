import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Item from './Item'
import axios from 'axios'
import Global from '../../../inc/Global.js'
import debounce from "lodash.debounce"; 
import configs from '../../../inc/helpers'
import PlusCircle from '../../icons/PlusCircle'
import BeatLoader from "react-spinners/BeatLoader";
const simpleTextSearch = require('simple-text-search')


class UsersList extends Component {
    constructor(props){
        super(props)
        var ModifiedUser = false;
        if(this.props.location && this.props.location.state && this.props.location.state.modifiedUser){
            ModifiedUser = this.props.location.state.modifiedUser
        }

        this.state = {
            users : [],
            AllUsers : [],
            limit : 10 ,
            step : 10,
            ModifiedUser : ModifiedUser ? true : false,
            loaded : false,
            allLoaded : false
        }

        this.LoadMore = React.createRef();
        this.searchB = React.createRef();
        // console.log(jwt_decode(localStorage.jwtToken))
        
        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.users.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }
    
    componentDidMount(){

        axios
        .get(Global.API_URL+"/api/users/0/1000000000")
        .then((res) => {
            var users = [];
            res.data.forEach(function(item, key) {
                users.push({
                    ID : item.id,
                    Email : item.email,
                    Name : item.profile ? item.profile.nom : "",
                    LastName : item.profile ? item.profile.prenom : "",
                    Photo : item.images ? item.images.image : "",
                    Job : item.profile ? item.profile.fonction : "",
                    PhoneNum : item.profile ? item.profile.telephone : "",
                    casecoche : (item.profile && item.profile.casecoche === '1') ? true : false
                })
            });
            this.setState({
                users : users,
                AllUsers : users,
                loaded : true
            })
            
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }
    
    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);

        const users = this.state.AllUsers.filter(item =>{
            let get = simpleTextSearch([item.Name+" "+item.LastName, item.Job, item.Email])
            return get(event.target.value).length > 0
        });
        await this.setState({ users });
        // await this.setState({
        //     limit : 5
        // })

        if(this.state.limit >= this.state.users.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }

    render() {
        // console.log(this.state.allLoaded, this.state.limit, this.state.AllUsers.length)
        return (
            <section className="content">
                <div className="wrap">
                    <div className="users-list">
                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3>Contacts ({this.state.users.length})</h3>
                                <div className="">
                                    <ul>

                                        {configs.isAdmin() &&
                                            <li>
                                                <Link to="/admin/utilisateur/ajouter">Créer un utilisateur <PlusCircle /></Link>
                                            </li>
                                        }
                                        {/* <li>
                                            <Link to="" onClick={(e)=> {e.preventDefault(); this.searchB.current.focus()}}><span className="fa fa-search"></span></Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="_srs-users">
                            <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Rechercher"/>
                            <Link to="" onClick={(e) => {
                                    e.preventDefault();
                                    this.searchB.current.setAttribute('val', ""); 
                                    this.searchB.current.value = ""; 
                                    this.setState({users : this.state.AllUsers
                                })}} className="button">Annuler</Link>
                        </div>
                        {this.state.modifiedUser ?
                            <div className="_alert _alert-success">L'utilisateur a été modifié avec succès</div>
                            :
                            ""
                        }
                        <div className="_ursl">
                            <ul>
                            {this.state.users.slice(0, this.state.limit).map((user, i) =>
                                <Item 
                                    key={user.ID}
                                    ID={user.ID}
                                    Name={user.Name}
                                    LastName={user.LastName}
                                    Photo={user.Photo}
                                    Job={user.Job}
                                    PhoneNum={user.PhoneNum}
                                    Email={user.Email} 
                                    casecoche={user.casecoche} />
                            )}
                            </ul>
                        </div>

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de données</span>
                                :
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            }

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default UsersList;