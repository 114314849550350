import React, { useEffect, useState } from 'react'  
import Global from '../../../inc/Global'
import Axios from 'axios';
import SHA1 from 'sha1'

//components
import {Form, Row, Col, Button, Tabs, Tab} from 'react-bootstrap';
import InputColor from 'react-input-color'; 
import BeatLoader from "react-spinners/BeatLoader";


const Settings = (props)=>{

    const [updatingSettings, setUpdatingSettings] =  useState(false)

    const [appName, setAppName] = useState('') 
    const [slogan, setSlogan] = useState('') 

    const [logo, setLogo] = useState(null) 
    const [logoFile, setLogoFile] = useState(null) 
    const [logoEmailing, setLogoEmailing] = useState(null) 
    const [logoEmailingFile, setLogoEmailingFile] = useState(null) 
    const [favicon, setFavicon] = useState(null) 
    const [faviconFile, setFaviconFile] = useState(null) 

    // Colors
    const [primaryColor, setPrimaryColor] = useState('#004b67')
    const [secondaryColor, setSecondaryColor] = useState('#34a5f8')

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
    // Web Colors 
        const [bodyBgColor, setBodyBgColor] = useState('#004b67')

        const [headerBgColor, setHeaderBgColor] = useState('#ffffff')
        const [headerMenuColor, setHeaderMenuColor] = useState('#004b67')
        const [headerActiveMenuColor, setHeaderActiveMenuColor] = useState('#34a5f8')

        const [communityColor, setCommunityColor] = useState('#004b67')
        const [communityBadgeColor, setCommunityBadgeColor] = useState('#ff0000')

        const [contactsColor, setContactsColor] = useState('#004b67') 

    

    const getDefaultWebColors = ()=>{
        return {
            "bodyBgColor": primaryColor,
            "headerBgColor":"#ffffff",
            "headerMenuColor":"#004b67ff",
            "headerActiveMenuColor":"#34a5f8ff",
            "communityColor": primaryColor,
            "communityBadgeColor":"#ff0000ff",
            "contactsColor": primaryColor
        }
    }

    const getAppConfig = ()=>{
        Axios
        .get(Global.API_URL + "/admin/platformget")
        .then((res) => {
            
            if( res.data.status ){

                let response = res.data.result[0]; 

                setAppName(response.nom)
                setSlogan(response.messageaccueil) 
                setLogo(response.logo && response.logo[0] ? Global.API_URL+"/"+response.logo[0] : null)
                setLogoEmailing(response.logo && response.logo[1] ? Global.API_URL+"/"+response.logo[1] : null)
                setFavicon(response.favicon ? Global.API_URL+"/"+response.favicon : null)
                

                // colors
                setPrimaryColor(response.couleurprinc)
                setSecondaryColor(response.couleurtab)

                let advancedColors = response.colorweb ? JSON.parse(response.colorweb) : getDefaultWebColors() 

                if(advancedColors){ 
                    setBodyBgColor(advancedColors.bodyBgColor)
                    setHeaderBgColor(advancedColors.headerBgColor)
                    setHeaderMenuColor(advancedColors.headerMenuColor)
                    setHeaderActiveMenuColor(advancedColors.headerActiveMenuColor)
                    setCommunityColor(advancedColors.communityColor)
                    setCommunityBadgeColor(advancedColors.communityBadgeColor)
                    setContactsColor(advancedColors.contactsColor)
                }
            }
        }) 
    }

    const saveConfigs = (e)=>{
        e.preventDefault()
        let data = new FormData()
        data.append('nom', appName)
        data.append('messageaccueil', slogan)

        if(logoFile) data.append('logo[0]', logoFile) 
        if(logoEmailingFile) data.append('logo[1]', logoEmailingFile)
        if(faviconFile) data.append('favicon', faviconFile) 

        data.append('couleurprinc', primaryColor) 
        data.append('couleurtab', secondaryColor) 
        data.append('colorweb', JSON.stringify({
            bodyBgColor: bodyBgColor,
            headerBgColor: headerBgColor,
            headerMenuColor: headerMenuColor,
            headerActiveMenuColor: headerActiveMenuColor,
            communityColor: communityColor,
            communityBadgeColor: communityBadgeColor,
            contactsColor: contactsColor,
        })) 

        ///
        setUpdatingSettings(true)
        Axios
        .post(Global.API_URL + "/api/admin/platforme",data).then((res) => {
            import('../../header/Header').then(_header=>{ 
                console.log(_header) 
                _header.default.setAppLogo(logo)
            })
            setUpdatingSettings(false)
        }).catch(err=>{
            setUpdatingSettings(false)
        })
    }


    const applyColors = () =>{
        document.documentElement.style.setProperty(`--primary-color`, primaryColor) 
        document.documentElement.style.setProperty(`--body-bg-color`, bodyBgColor)
        document.documentElement.style.setProperty(`--header-bg-color`, headerBgColor)
        document.documentElement.style.setProperty(`--header-menu-color`, headerMenuColor)
        document.documentElement.style.setProperty(`--header-active-menu-color`, headerActiveMenuColor)
        document.documentElement.style.setProperty(`--header-profile-color`, headerMenuColor) 

        document.documentElement.style.setProperty(`--communauty-name-color`, communityColor)
        document.documentElement.style.setProperty(`--communauty-badge-color`, communityBadgeColor)

        document.documentElement.style.setProperty(`--contact-color`, contactsColor)

        // favIcon
        document.getElementById('favicon').href = favicon
    }

 
    useEffect(() => { 
        getAppConfig()
    }, []); 

    applyColors()
    return(
        <>
            <h2>Nom et icones / Couleur</h2>
            <p>Modifiez le nom de la plate-forme, les logos et les couleurs de la plate-forme.</p>
            <Form onSubmit={(e)=>{saveConfigs(e)}}>

                <Tabs defaultActiveKey="tab-1" id="settings">
                    <Tab eventKey="tab-1" title="Général">
                        <Form.Group controlId="tab--1">
                            <Form.Label>Nom de la plateforme</Form.Label>
                            <Form.Control type="text" name="name" id="name" placeholder="" value={appName} onChange={(e)=>{setAppName(e.target.value)}} />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Page de connexion</Form.Label>
                            <Form.Control type="text" name="slogan" id="slogan" placeholder="" value={slogan} onChange={(e)=>{setSlogan(e.target.value)}} /> 
                        </Form.Group>

                        <Form.Group>
                            {
                                logo &&
                                <img src={logo} style={{height: 51}} />
                            } 
                            <Form.File 
                            accept="image/*"
                            onChange={(e)=>{ 
                                setLogo( URL.createObjectURL(e.target.files[0]) )
                                setLogoFile( e.target.files[0] )
                            }}
                            name="logo" id="logo" label="Logo de la plateforme" />
                        </Form.Group>

                        <Form.Group>
                            {
                                logoEmailing &&
                                <img src={logoEmailing} style={{height: 51}} />
                            } 
                            <Form.File 
                            accept="image/*"
                            onChange={(e)=>{ 
                                setLogoEmailing( URL.createObjectURL(e.target.files[0]) )
                                setLogoEmailingFile( e.target.files[0] )
                            }}
                            name="logo" id="logo" label="Logo pour les e-mails" />
                        </Form.Group>

                        <Form.Group>
                            {
                                favicon &&
                                <img src={favicon} style={{width: 30}} />
                            } 
                            <Form.File 
                            accept="image/*"
                            onChange={(e)=>{ 
                                setFavicon( URL.createObjectURL(e.target.files[0]) )
                                setFaviconFile( e.target.files[0] )
                            }}
                            name="favicon" id="favicon" label="Icone onglet navigateur" />
                        </Form.Group>
                    </Tab> 

                    <Tab eventKey="tab-2" title="Couleurs">

                        <Row> 
                            <Col>
                                <Form.Group controlId="tab--1">
                                    <Form.Label>Couleur principal</Form.Label>
                                    <InputColor
                                        className="input-color"
                                        initialValue={primaryColor}
                                        onChange={(color)=>{
                                            setPrimaryColor(color.hex); 
                                            setBodyBgColor(color.hex); 
                                            setHeaderMenuColor(color.hex); 
                                            setCommunityColor(color.hex); 
                                            setContactsColor(color.hex); 
                                        }}
                                        placement="right"
                                    />
                                </Form.Group> 
                            </Col> 
                        </Row> 

                        <hr />
                        <label>Communautés</label>
                        <Row> 
                            <Col>
                                <Form.Group controlId="tab--1">
                                    <Form.Label>Nom des Communautés</Form.Label>
                                    <InputColor
                                        className="input-color"
                                        initialValue={communityColor}
                                        onChange={(color)=>{setCommunityColor(color.hex)}}
                                        placement="right"
                                    />
                                </Form.Group> 
                            </Col> 
                            <Col>
                                <Form.Group controlId="tab--1">
                                    <Form.Label>Pastille de notifications</Form.Label>
                                    <InputColor
                                        className="input-color"
                                        initialValue={communityBadgeColor}
                                        onChange={(color)=>{setCommunityBadgeColor(color.hex)}}
                                        placement="right"
                                    />
                                </Form.Group> 
                            </Col> 
                        </Row> 
                        <hr />
                        <Row> 
                            <Col>
                                <Form.Group controlId="tab--1">
                                    <Form.Label>Contacts</Form.Label>
                                    <InputColor
                                        className="input-color"
                                        initialValue={contactsColor}
                                        onChange={(color)=>{setContactsColor(color.hex)}}
                                        placement="right"
                                    />
                                </Form.Group> 
                            </Col>   
                        </Row> 

                        <a href="#" className="link" onClick={()=>{setShowAdvancedOptions(!showAdvancedOptions)}}>Options avancées pour la version Web</a>

                        {
                            showAdvancedOptions &&
                            <div className="web-colors">
                                <Row> 
                                    <Col>
                                        <Form.Group controlId="tab--1">
                                            <Form.Label>Couleur de l'arrière plan</Form.Label>
                                            <InputColor
                                                className="input-color"
                                                initialValue={bodyBgColor}
                                                onChange={(color)=>{setBodyBgColor(color.hex)}}
                                                placement="right"
                                            />
                                        </Form.Group> 
                                    </Col> 
                                </Row> 

                                <hr />
                                <label>Barre de navigation</label>
                                <Row> 
                                    <Col>
                                        <Form.Group controlId="tab--1">
                                            <Form.Label>Arrière plan de la barre</Form.Label>
                                            <InputColor
                                                className="input-color"
                                                initialValue={headerBgColor}
                                                onChange={(color)=>{setHeaderBgColor(color.hex)}}
                                                placement="right"
                                            />
                                        </Form.Group> 
                                    </Col> 
                                    <Col>
                                        <Form.Group controlId="tab--1">
                                            <Form.Label>Menu</Form.Label>
                                            <InputColor
                                                className="input-color"
                                                initialValue={headerMenuColor}
                                                onChange={(color)=>{setHeaderMenuColor(color.hex)}}
                                                placement="right"
                                            />
                                        </Form.Group> 
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="tab--1">
                                            <Form.Label>Menu active</Form.Label>
                                            <InputColor
                                                className="input-color"
                                                initialValue={headerActiveMenuColor}
                                                onChange={(color)=>{setHeaderActiveMenuColor(color.hex)}}
                                                placement="right"
                                            />
                                        </Form.Group> 
                                    </Col> 
                                </Row>  
                            </div>
                        } 

                    </Tab> 
                </Tabs>

                    
                
                <div className="submit-container">
                    <Button variant="primary" type="submit"  disabled={updatingSettings}>
                        Enregister les modification
                        <BeatLoader color={"#aaa"} loading={updatingSettings} size="10"/>
                    </Button>
                </div>
            </Form>
        </>
    )
}
export default Settings