import React, { useEffect, useState } from 'react'  
import Global from '../../../inc/Global'
import Axios from 'axios';
import SHA1 from 'sha1'
import RightSidebar from '../../aside/RightSidebar'

//components
import {Form, Row, Col, Button, Dropdown} from 'react-bootstrap'; 
import BeatLoader from "react-spinners/BeatLoader";

var self

const Banners = (props)=>{

    //State
    const [listing, setListing] = useState([]) 
    const [type, setType] = useState('Image')
    const [file, setFile] =  useState(null)
    const [link, setLink] =  useState('')
    const [addingItem, setAddingItem] =  useState(false)
    


    useEffect(() => { 
        getListing()
    }, []); 

    const getListing = ()=>{
        Axios.get(Global.API_URL + "/admin/getlist").then((res) => {
            if( res.data.status ) setListing(res.data.result)  
        })
    } 

    const deleteItem = (item)=>{
        Axios.delete(Global.API_URL + "/api/deleteimagevideo/"+item.id).then((res) => {
            if( res.data.status ){
                RightSidebar._getListing()
                getListing()
            } 
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()  
        setAddingItem(true)

        let data = new FormData()
        data.append('file', file)
        data.append('lien', type == 'Image' ? link : '') 
        ///
        Axios.post(Global.API_URL + "/api/admin/misavant",data).then((res) => {
            getListing();
            document.getElementById("add-banner").reset();
            setType('Image')
            setFile(null)
            setLink('')
            setAddingItem(false)

            RightSidebar._getListing()
        })
    } 
    return(
        <div className="administration-banners">
            <h2>Mise en avant</h2>
            <p>Placez l'image de la mise en avant par défaut de la colonne de droite</p>

            <div className="listing"> 
                <Row> 
                    <Col xs={4}>Illustration</Col> 
                    <Col xs={6}>Lien</Col> 
                    <Col xs={2}>Action</Col> 
                </Row> 
                {listing.map(item=>(
                    <Row> 
                        <Col xs={4}> 
                            {
                                item.video 
                                ?
                                    <div className="item-image" style={{backgroundImage: `url(${Global.API_URL}/${item.thumbs})`}}></div>
                                : 
                                    <div className="item-image" style={{backgroundImage: `url(${Global.API_URL}/${item.imageplatform})`}}></div>
                            } 
                        </Col> 
                        <Col xs={6} className="link">
                            {
                                !item.video &&
                                <a href={item.link} target="_blank">{ item.link }</a>
                            }
                        </Col> 
                        <Col xs={2}>
                            <button className="delete-item" onClick={()=>{deleteItem(item)}}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Col> 
                    </Row> 
                ))}
            </div>

            <Form onSubmit={ onSubmit } id="add-banner"> 
                <h2>Nouvelle Illustration</h2>
                <Row> 
                    <Col>
                        <Form.Group> 
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                { type }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={()=>{setType('Image')}}>Image</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={()=>{setType('Vidéo')}}>Vidéo</Dropdown.Item> 
                            </Dropdown.Menu>
                        </Dropdown>
                        </Form.Group> 
                    </Col> 
                </Row> 
                <Row> 
                    <Col>
                        <Form.Group> 
                            <Form.File 
                            accept={type == 'Image' ? 'image/*' : 'video/*'}
                            required
                            onChange={(e)=>{ 
                                setFile(e.target.files[0])
                            }}
                            name="logo" id="logo" label={ type == 'Image' ? 'Illustration' : 'Video'} />
                        </Form.Group> 
                    </Col> 
                </Row> 

                {
                    type == 'Image' &&
                    <Row> 
                        <Col>
                            <Form.Group>
                                <Form.Label>Lien</Form.Label>
                                <Form.Control 
                                type="text" name="link" id="link" placeholder="" value={link} 
                                onChange={(e)=>{setLink(e.target.value)}} /> 
                            </Form.Group> 
                        </Col> 
                    </Row> 
                }
                <div className="submit-container">
                    <Button variant="primary" type="submit" disabled={addingItem}>
                        Ajouter
                        <BeatLoader color={"#aaa"} loading={addingItem} size="10"/>
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default Banners