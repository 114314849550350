import Axios from 'axios'
import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import BeatLoader from "react-spinners/BeatLoader";
import Global from '../../inc/Global'
import configs from '../../inc/helpers'

const Poll = ({post, auth}) => {
    
    const [reponsepoll, setSeponsepoll] = useState(post.reponsepoll)
    const [votant, setVotant] = useState(post.votant)
    const [showVotingUsers, setShowVotingUsers] = useState(false)
    const [votingUsers, setVotingUsers] = useState([])
    const [loadingUser, setLoadingUser] = useState(false)
    
    const redisterResponse = (e)=>{  
        let responseId = e.target.value;
        let data = new FormData() 
        data.append('email', auth.user.username)
        data.append('idpoll', post.id)
        data.append('idgroup', post.groups.id)
        data.append('idchoice', responseId)

        Axios
        .post(Global.API_URL+'/api/pollresponse', data)
        .then((res) => {
            if(res.data.status){
                setSeponsepoll(res.data.reponsepool)
                setVotant([true, parseInt(responseId)]) 
            }
        }) 
    }

    const getVotingUsers = (reponse, index)=>{
        if(reponse.nbvotant === 0 && showVotingUsers === null) return;
        setShowVotingUsers(index); 
        setLoadingUser(true)
        setVotingUsers([])
        Axios
        .post(Global.API_URL+'/api/pollreaction', {
            idgroup: post.groups.id,
            idchoice: reponse.id
        })
        .then((res) => {
            setLoadingUser(false)
            if(res.data){
                setVotingUsers(res.data)
            }
        }) 
    }

    return(
        
        <>
            <ul className="poll-responses"> 
                { Array.isArray(reponsepoll) ?
                    reponsepoll.map((reponse, index) => {
                        return(
                            <li className="d-flex" key={index}>
                                <div>
                                    <input type="radio" onChange={(e)=>{redisterResponse(e)}} checked={votant.length > 0 && reponse.id ===  votant[1]} className={post.leftime === '0' ? 'prevent' : ''} name={'response_post_'+post.id} value={reponse.id} />
                                </div>
                                <div>
                                    <div className="response-container">
                                        <div className="progress-bar" style={{width: reponse.pourcentage}}></div>
                                        <p className="response">{reponse.answer}</p>
                                    </div>
                                </div>
                                
                                <Popover
                                    containerClassName="poll-users-list" 
                                    positions={['top']} // if you'd like, you can limit the positions  
                                    onClickOutside={() => {setShowVotingUsers(null)}} // handle click events outside of the popover/target here! 
                                    isOpen={showVotingUsers === index} content={()=>{
                                    return(
                                        <>
                                            {
                                                loadingUser &&
                                                <center>
                                                    <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                                </center>
                                            }
                                            {
                                                votingUsers.length > 0 &&
                                                <ul className="users-list">
                                                    {votingUsers.map(user=>{
                                                        return(
                                                            <li id={`user-id-${user.id}`}>
                                                                <a href="#" onClick={(e) => {e.preventDefault();}}>
                                                                    <figure 
                                                                        className="" 
                                                                        style={ user.image ? {backgroundImage: 'url('+ Global.API_URL + '/'+ user.image + ')'}
                                                                            : 
                                                                            {}
                                                                        }> 
                                                                            {!user.image && 
                                                                                <span>{user?.prenom?.charAt(0)+user.nom?.charAt(0)}</span>
                                                                            } 
                                                                    </figure>
                                                                    <div>
                                                                        <h3 className="user-name">{user.prenom.toLowerCase()} {user.nom.toLowerCase()}</h3> 
                                                                    </div>
                                                                </a>
                                                            </li> 
                                                        )
                                                    })} 
                                                </ul>
                                            } 
                                        </>
                                    )
                                }}>
                                    <div onClick={()=>{getVotingUsers(reponse, index)}}>
                                        {
                                            reponse.pourcentage &&
                                            <strong>{parseFloat(reponse.pourcentage).toFixed(2)+"%" || '0%'}</strong>
                                        }
                                        {
                                            reponse.nbvotant 
                                            ?
                                            <span>{reponse.nbvotant === 1 ? '1 Vote' : reponse.nbvotant + ' Votes'}</span>
                                            :
                                            <span>0 Vote</span>
                                        }
                                        
                                    </div>
                                </Popover> 
                            </li>
                        )
                    })
                    :
                    console.log(reponsepoll)
                }
            </ul>
            <p style={{color: '#7c7c7c',fontSize: 14}}>
                <i className="fa fa-clock-o" aria-hidden="true"></i> 
                <span>&nbsp;Clôture du sondage le {configs.frenchDateFormat(post.endate)}</span>
            </p>
        </>
    )
}

export default Poll