import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import UserItem from './UserItem'
import axios from 'axios'
import Global from '../../inc/Global.js'
import debounce from "lodash.debounce";
import configs from '../../inc/helpers'
import BeatLoader from "react-spinners/BeatLoader";
const simpleTextSearch = require('simple-text-search')

class UsersOfCommunity extends Component {
    constructor(props){
        super(props)
        
        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }
        
        const params = this.props.location.state;

        this.state = {
            groupId : params.id,
            users : [],
            AllUsers : [],
            limit : 10 ,
            step : 10,
            loaded : false,
            allLoaded : false,
            userToDeleteFromCommunity : null
        }

        this.LoadMore = React.createRef();
        this.DeleteBtn = React.createRef();
        this.searchB = React.createRef();
        
        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.users.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }
    
    componentDidMount(){
        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }

        axios
        .post(Global.API_URL+"/useringroups", {id : this.state.groupId})
        .then((res) => {
            var users = []; 
            var useradmin = []
            if( res.data.useradmin.length > 0 && res.data.useradmin[0].user ){
                useradmin = res.data.useradmin;
            }
            useradmin.concat(res.data.users).forEach(function(item, key) { 
                users.push({
                    ID : item.user.id,
                    Email : item.user.email,
                    Name : item.user.profile ? item.user.profile.nom : "",
                    LastName : item.user.profile ? item.user.profile.prenom : "",
                    Photo : item.user.images ? item.user.images.image : "",
                    Job : item.user.profile ? item.user.profile.fonction : "",
                    PhoneNum : item.user.profile ? item.user.profile.telephone : "",
                    Role : item.user_role
                }); 
            });
            

            users = [...new Map(users.map(item => [JSON.stringify(item), item])).values()];

            this.setState({
                users : users,
                AllUsers : users,
                loaded : true
            })
            if(this.state.limit >= users.length){
                this.setState({
                    allLoaded : true
                })
            }else{
                this.setState({
                    allLoaded : false
                })
            }
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }
  
    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);

        const users = this.state.AllUsers.filter(item =>{
            let get = simpleTextSearch([item.Name+" "+item.LastName, item.Job, item.Email])
            return get(event.target.value).length > 0
        });
        await this.setState({ users });
        // await this.setState({
        //     limit : 10
        // })

        if(this.state.limit >= this.state.users.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    confirmDeletion (e){
        e.preventDefault();
        var btn = this.DeleteBtn;
        btn.current.classList.add('on');
        
        
        axios
        .post(Global.API_URL+"/api/userremovegroup", {id : this.state.groupId, iduser : this.state.userToDeleteFromCommunity})
        .then((res) => {
            btn.current.classList.remove('on');

            if(res.data){

                document.getElementById("user-id-"+this.state.userToDeleteFromCommunity).remove()

                var users = [...this.state.users]; 
                var AllUsers = [...this.state.AllUsers];
                
                var currentUser = configs.getIndexWidthProperty(users, "ID", this.state.groupId);
                var currentAllUser = configs.getIndexWidthProperty(AllUsers, "ID", this.state.groupId);
                
                // users.forEach(function(el, ind){
                    
                //     if(el.ID === parseInt(this.state.userToDeleteFromCommunity)){
                //         currentUser = ind;
                //     }
                // }.bind(this));
            
                // AllUsers.forEach(function(el, ind){
                
                //     if(el.ID === parseInt(this.state.userToDeleteFromCommunity)){
                //         currentAllUser = ind; 
                //     }
                // }.bind(this));
                
                // console.log(currentUser);
                
                users.splice(currentUser, 1)
                AllUsers.splice(currentAllUser, 1) 
                    
                this.setState({
                    userToDeleteFromCommunity : null,
                    users : users,
                    AllUsers : AllUsers
                });

                this.componentDidMount(); 
            }
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
        
        
    }
    handleDelete (e) {
        e.preventDefault();
        this.setState({
            userToDeleteFromCommunity : e.target.getAttribute('id')
        });
    }
    cancel (e){
        e.preventDefault();
        this.setState({
            userToDeleteFromCommunity : null
        });
    }
    render() {
        if(typeof this.props.location.state === "undefined" ){
            this.props.history.push(`/communaute/${this.state.groupId}/parametres`)
            return "";
        }else if(typeof this.props.location.state.id === "undefined"){
            this.props.history.push(`/communaute/${this.state.groupId}/parametres`)
            return "";
        }

        const rolesItems = [
            { value: 'ROLE_ADMIN', label: 'Administrateur' },
            { value: 'ROLE_MODERATEUR', label: 'Rédacteur'},
            { value: 'ROLE_LECTEUR', label: 'Lecteur' }
        ]

        const getUserRole = (userRole) => {
            return rolesItems.find((role)=>{ return role.value ==  userRole})
        }

        return (
            <section className="content">
                <div className="wrap">
                    <div className="users-list"> 

                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3 className="h3"> 
                                    <center>
                                        <Link to={`#`}  onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                            <span className="fa fa-long-arrow-left"></span>
                                        </Link> 
                                        Membres de la communauté 
                                    </center>
                                </h3>  
                            </div>
                        </div> 

                        <div className="_srs-users">
                            <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Rechercher un utilisateur"/>
                            <Link to="" onClick={(e) => {
                                    e.preventDefault();
                                    this.searchB.current.setAttribute('val', ""); 
                                    this.searchB.current.value = ""; 
                                    this.setState({users : this.state.AllUsers
                                })}} className="button">Annuler</Link>
                        </div>
                        
                        {this.state.userToDeleteFromCommunity && 
                            <div className="_modal confirmation" id="CropImageModal">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir désabonner cet utilisateur de cette communauté&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Désabonner
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="_ursl">
                            <ul>
                            {this.state.users.slice(0, this.state.limit).map((user, i) =>
                                <UserItem 
                                    key={"__ID" + user.ID}
                                    ID={user.ID}
                                    Name={user.Name}
                                    LastName={user.LastName}
                                    Photo={user.Photo}
                                    Job={user.Job}
                                    PhoneNum={user.PhoneNum}
                                    Email={user.Email}
                                    RoleSelect={{
                                        items : rolesItems,
                                        selectedRole : getUserRole(user.Role),
                                        selectedValue : getUserRole(user.Role)
                                    }}
                                    GroupId={this.state.groupId}
                                    handleDelete={(e)=>{this.handleDelete(e)}}
                                />
                            )}
                            </ul>
                        </div>

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de données</span>
                                :
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            }

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default UsersOfCommunity;