import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Global from '../../inc/Global'
import configs from '../../inc/helpers';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Picker from 'react-giphy-component';
import PickerEmoji from 'emoji-picker-react';
import Axios from 'axios';


import Editor from '@draft-js-plugins/editor';
import createMentionPlugin ,{defaultSuggestionsFilter}from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { EditorState , convertToRaw ,ContentState,SelectionState,Modifier,setEditorState} from 'draft-js';






// import createHashtagPlugin from '@draft-js-plugins/hashtag';
// import createLinkifyPlugin from '@draft-js-plugins/linkify';

// const hashtagPlugin = createHashtagPlugin();
// const linkifyPlugin = createLinkifyPlugin();


class CreateComment extends Component {
    constructor(props){
        super(props);
        this.mentionPlugin = createMentionPlugin({supportWhitespace:true});
        this.state = {
            editorState: EditorState.createEmpty(),
            open: false,
            suggestions: [],              
            mentions: [], 
            postID : this.props.postid ? this.props.postid : 0,
            groupID : this.props.groupid ? this.props.groupid : 0,
            text : "",
            gif : null,
            gifSmall : null,
            files : [],
            images : [],
            type : "onlyText",
            fileSelected : false,
            error : false,
            storyHeight : 1,
            uploadPercentage : 0,
            isResponse : this.props.isResponse ? true: false,
            commentID : this.props.commentid ? this.props.commentid : 0,
            isSharingGif: false,
            isSharingEmoji: false,
            txtHeight : 0, 
            placeholder: this.props.placeholder || "Ajouter un commentaire..."
        }

        // Refs
        this.shareIconBtn= React.createRef(null);
        this.shareEmojiBtn = React.createRef();
        this.textarea = React.createRef();
        this.InputImage = React.createRef();
        this.txtVal = React.createRef();

        //Functions
        this.handleGifChange = this.handleGifChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleAddComment = this.handleAddComment.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mouseup', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    handleClickOutside(event) {
        
        if (this.shareIconBtn && !this.shareIconBtn.current.contains(event.target) && document.querySelector('.share-icon .icons-list .sc-bdVaJa') && ! document.querySelector('.share-icon .icons-list .sc-bdVaJa').contains(event.target)) {
            this.setState({
                isSharingGif: false
            })
            this.shareIconBtn.current.classList.remove('on');
        }
        if (this.shareEmojiBtn && !this.shareEmojiBtn.current.contains(event.target) && document.querySelector('.share-icon .emoji-list .emoji-picker-react') && ! document.querySelector('.share-icon .emoji-list .emoji-picker-react').contains(event.target)) {
            this.setState({
                isSharingEmoji: false
            })
            this.shareEmojiBtn.current.classList.remove('on');
        }
    }
    handleAddComment (ev){
        
        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({
            disabled : true
        })

        const tagueArry= this.getTextFromTextEditor()
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent < 100 ){
                    this.setState({ uploadPercentage: percent })
                }
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        var path;
        if(this.state.isResponse){
            path = "";
            let data = {};

            if(this.state.images.length !== 0){
                path = "/api/comment/reponse/file";
                data = new FormData();

                data.append('userEmail', this.props.auth.user.username);
                data.append('commentID', this.state.commentID);
                data.append('groupID', this.state.groupID);
                data.append('text', tagueArry.newText);
                data.append('gif', this.state.gif);
                data.append('image', this.state.files[0]);
                if (tagueArry.MentionArrToSend) {
                    for(var $j = 0;$j < tagueArry.MentionArrToSend.length; $j++){
                        data.append('emailtager[]', tagueArry.MentionArrToSend[$j]);
                    }
                }
            }else{
                path = "/api/comment/reponse/null";

                data = {
                    userEmail : this.props.auth.user.username,
                    commentID : this.state.commentID,
                    groupID : this.state.groupID,
                    text : tagueArry.newText,
                    gif : this.state.gif,
                    emailtager: tagueArry.MentionArrToSend
                }
            }
            
            Axios
            .post(Global.API_URL + path, data, options)
            .then((res) => {
                this.props.onCreateResponse(res, this.state.postID, this.state.commentID);
                console.log("txtHeight", res)
                this.setState({ 
                    uploadPercentage: 0 ,
                    images: [],
                    files : [],
                    gif : null,
                    gifSmall : null,
                    text: "",
                    type : 'onlyText',
                    disablePost : false,
                    disabled : false,
                    txtHeight: 0,
                    editorState:editorState
                });
            })
            .catch((err) => {
                // console.log('Error : '+err.message)
            });
        } else {
            path = "";
            let data = {};

            if(this.state.images.length !== 0){
                path = "/api/comment/create/file";
                data = new FormData();

                data.append('userEmail', this.props.auth.user.username);
                data.append('postID', this.state.postID);
                data.append('groupID', this.state.groupID);
                data.append('text',  tagueArry.newText);
                data.append('gif', this.state.gif);
                data.append('image', this.state.files[0]);
                if (tagueArry.MentionArrToSend) {
                    for(var $j = 0;$j < tagueArry.MentionArrToSend.length; $j++){
                        data.append('emailtager[]', tagueArry.MentionArrToSend[$j]);
                    }
                }
            }else{
                path = "/api/comment/create/null";

                data = {
                    userEmail : this.props.auth.user.username,
                    postID : this.state.postID,
                    groupID : this.state.groupID,
                    text :  tagueArry.newText,
                    gif : this.state.gif,
                    emailtager: tagueArry.MentionArrToSend
                }
            }

            Axios
            .post(Global.API_URL+path, data, options)
            .then((res) => {
                this.props.onCreateComment(res, this.state.postID);
                this.setState({ 
                    uploadPercentage: 0 ,
                    images: [],
                    files : [],
                    gif : null,
                    gifSmall : null,
                    text: "",
                    type : 'onlyText',
                    disablePost : false,
                    disabled : false,
                    txtHeight: 0,
                    editorState:editorState
                });
            })
            .catch((err) => {
                // console.log('Error : '+err.message)
            });
        }
    }
    handleChange(ev) {
        this.setState({
            text : ev.target.value,
            error : false,
            storyHeight : this.textarea.current.value.substr(0, this.textarea.current.selectionStart).split("\n").length
        }, () => {
            this.setState({
                txtHeight: this.txtVal.current.clientHeight
            });
        })
    }

    /* Share Gif Functions */
    handleShareIcon(ev){
        ev.preventDefault();
        this.setState({
            isSharingGif : true
        })
        this.shareIconBtn.current.classList.toggle('on');
        // console.log(ev.target)
    }
    handleGifChange (gif){
        this.setState({
            gif : gif.original.url,
            gifSmall : gif.preview_gif.url,
            files : [],
            images : [],
            type : 'gif',
            fileSelected : true,
            isSharingGif: false
        })
         
        this.shareIconBtn.current.classList.remove('on')
        this.textarea.current.focus();
    }
    removeGif (ev){
        ev.preventDefault();
        this.setState({
            gif : null,
            gifSmall : null
        })
    }
    // End / Share Gif Functions
    
    // Share Imoji Functions
    handleEmoji (ev){
        ev.preventDefault();
        this.setState({
            isSharingEmoji : true
        })
        ev.target.classList.toggle('on');
        // console.log(ev.target);
    }
    onEmojiClick = (event, emojiObject) => {
        event.preventDefault();

        // console.log(this.state.text);
        this.setState({
            text: this.state.text + emojiObject.emoji,
            isSharingEmoji : false
        });
        
        let emoji =  emojiObject.emoji;
        this.setState({editorState: this.insertCharacter(emoji, this.state.editorState)});
        // this.textarea.current.focus();

    };
    // End / Share Imoji Functions
    
    // Share Image Functions
    handleImageChange (e){
        //e.preventDefault();
        var images;
        var files;

        // if(this.state.type === "images"){
        //     images = this.state.images;
        //     files = this.state.files;
        // }else{
        //}
        
        images = [];
        files = [];

        for(var i = 0; i < e.target.files.length; i++){
            images.push(URL.createObjectURL(e.target.files[i]));
            files.push(e.target.files[i]);
        }

        this.setState({
            images: images,
            files : files,
            gif : null,
            gifSmall : null,
            type : 'images',
            fileSelected : true
        })

        this.InputImage.current.value = null;
        this.textarea.current.focus();
    }
    removeImage(ev){
        var target = ev.target.getAttribute('data-target');
        var images = this.state.images;
        var files = this.state.files;
        images.splice(target, 1);
        files.splice(target, 1);

        this.setState({
            images : images,
            files : files
        })
    }
    // End / Share Image Functions
    // mention (tag) in comentair

    focus = () => {
        this.editor.focus();
    };
    getAllEditorText(){
        const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        return value
    }
    getTextFromTextEditor = ()=>{
        const contentState =  this.state.editorState.getCurrentContent();
        const rocontentStateRow = convertToRaw(contentState);
        let textBlocks = rocontentStateRow.blocks
        let mentionObj = rocontentStateRow.entityMap
        let mentionArr = Object.values(mentionObj);
        let newText='';
        let MentionArrToSend=[];
        newText = this.getAllEditorText()
        console.log('newText',newText);
        if (mentionArr.length>0) {
            for (let index = 0; index < mentionArr.length; index++) {
                const element = mentionArr[index];
                if (element.type=="mention"){
                    MentionArrToSend.push(element.data.mention.email)
                    newText = newText.replace(element.data.mention.name,`<span class="tagueName" id="userId-${element.data.mention.id}" data-id="168">@${element.data.mention.name.split(' ')[0]}</span>`)
                }
            }
        }
        this.setState({
            text : newText,
            // error : false
        });

        // var testUrl = newText.match(/(https?:\/\/[^\s]+\.[^\s]+)/),
        // onlyUrl = testUrl && testUrl[1];
        // if(onlyUrl){
        //     this.setState({
        //         link : onlyUrl,
        //         type: 'link'
        //     })
        // }
        console.log('2=newText',newText);

        return {
            newText:newText,
            MentionArrToSend:MentionArrToSend.length>0 ? MentionArrToSend : null
        };
    }
    onChangeEditor = editorState => {
        
        this.setState(
            {
                editorState
            },
            ()=>{
                let newText = this.getAllEditorText()
                this.setState({
                    text : (newText && newText!=' ' && newText != '\n')? newText : "",
                })
            }
        );
    };
    onSearchChange= ({value})=>{
        this.setState({
            suggestions : defaultSuggestionsFilter(value,this.state.mentions)
          });
    }
    onOpenChange = (_open)=>{

        if(this.state.groupID <= 0){
            this.setState({
                communityHasSelected : false
            })
            return;
        }
        let groupID = this.state.groupID
        let data = {
            'idgroup':groupID
        }
        Axios.post(`${Global.API_URL}/api/tag`,data).then((res) => {
            if (res && res.data) {
               let newArr=[]
               for (let index = 0; index < res.data.length; index++) {
                   const element = res.data[index];
                   newArr.push({
                        link:  element.email,
                        avatar:  `${Global.API_URL}/${element.image}`,
                        name:  `${element.prenom} ${element.nom}`,
                        id:  element.id,
                        email:  element.email,
                   })
               }
               this.setState({
                    open:_open,
                    suggestions: newArr, 
                    mentions: newArr, 
                });
            }
        }).catch((err) => {
            // console.log(err.message);
        });
        
        
    }
    insertCharacter(characterToInsert, editorState) {
        const currentContent = editorState.getCurrentContent(),
        currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            characterToInsert
        );

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

        return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }
    render() {
        // console.log(this.props)
        const { MentionSuggestions } = this.mentionPlugin;
            const plugins = [this.mentionPlugin];
        return (
            <div>
                <div className="add-comment draftTxtArea">
                    <div className="d-flex zone-text ">
                        <div className="thumb" style={this.props.auth.user.profil !== '0' ?
                            {backgroundImage: 'url('+ Global.API_URL +"/"+ this.props.auth.user.profil +')' }
                            :
                            {}
                        }>
                        {
                            (this.props.auth.user.profil === '0'  && this.props.auth.user.name && this.props.auth.user.lastname) &&
                            this.props.auth.user.name.charAt(0)+this.props.auth.user.lastname.charAt(0)
                        }
                        </div>
                        <div className={"textarea " + (this.state.disabled ? "disabled" : "" )} onClick={this.focus}>
                            <span ref={this.txtVal} className="txtVal">
                            {this.state.text && this.state.text.split("\n").map(function(item, idx) {
                                return (
                                    <span key={"_txt"+idx}>
                                    {item}
                                    <br/>
                                    </span>
                                    )
                                })
                            }
                            </span>
                            {/* <textarea 
                                rows={Math.round(this.state.storyHeight)} 
                                ref={this.textarea} 
                                placeholder={this.state.placeholder} 
                                name="newpost" 
                                id="newpost" 
                                onChange={this.handleChange.bind(this)} 
                                value={this.state.text}
                                style={{height: this.state.txtHeight + 'px'}}></textarea> */}

                            <div className="textEditorAddComment">
                                <Editor
                                    editorState={this.state.editorState}
                                    onChange={this.onChangeEditor.bind(this)}
                                    plugins={plugins}
                                    placeholder={"Ecrire une publication"} 
                                    value={this.state.text} 
                                    ClassName="PostEditor"
                                    ref={(element) => { this.editor = element; }}
                                />
                                <MentionSuggestions  
                                    open={this.state.open} 
                                    onOpenChange={this.onOpenChange} 
                                    onSearchChange={this.onSearchChange.bind(this)} 
                                    suggestions={this.state.suggestions} 
                                />
                            </div>

                            {(this.state.text || this.state.gif || this.state.images.length !== 0) && 
                                <div className="text-right">
                                    <span className="button m-t-10" onClick={this.handleAddComment}>Publier</span>
                                </div>
                            }
                            {/* <div className="attachments">
                                <span className="gif-icon share-icon">
                                    <img ref={this.shareEmojiBtn} onClick={this.handleEmoji.bind(this)} src={configs.GetImage('happy.png')} alt="" />

                                    {this.state.isSharingEmoji &&
                                        <div className="emoji-list">
                                            <PickerEmoji onEmojiClick={this.onEmojiClick} style={{position : 'fixed'}} />
                                        </div>
                                    }
                                </span>
                                <span className="happy-icon share-icon">
                                    <span ref={this.shareIconBtn} onClick={this.handleShareIcon.bind(this)} className="">
                                        <img src={configs.GetImage('gif.png')} alt="" />
                                    </span>
                                    
                                    {this.state.isSharingGif && 
                                        <div className="icons-list">
                                            <Picker apiKey="u3PkS4gInlk051bpDTIbPo7Ga9H6ZDc3" placeholder="Recherche" onSelected={this.handleGifChange.bind(this)} style={{position : 'fixed'}} />
                                        </div>
                                    }
                                </span>
                                <span className="camera-icon">
                                    <label htmlFor={"uploadImageForComment-" + this.state.postID}>
                                        <input 
                                            ref={this.InputImage}
                                            type="file" 
                                            name="uploadImages" 
                                            id={"uploadImageForComment-" + this.state.postID} 
                                            accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                            onChange={this.handleImageChange.bind(this)} />
                                        <img src={configs.GetImage('camera.png')} alt="" />
                                    </label>
                                </span>
                            </div> */}
                        </div>
                        <div className="attachments">
                            <span className="gif-icon share-icon">
                                <img ref={this.shareEmojiBtn} onClick={this.handleEmoji.bind(this)} src={configs.GetImage('happy.png')} alt="" />

                                {this.state.isSharingEmoji &&
                                    <div className="emoji-list">
                                        <PickerEmoji onEmojiClick={this.onEmojiClick} style={{position : 'fixed'}} />
                                    </div>
                                }
                            </span>
                            <span className="happy-icon share-icon">
                                <span ref={this.shareIconBtn} onClick={this.handleShareIcon.bind(this)} className="">
                                    <img src={configs.GetImage('gif.png')} alt="" />
                                </span>
                                
                                {this.state.isSharingGif && 
                                    <div className="icons-list">
                                        <Picker apiKey="u3PkS4gInlk051bpDTIbPo7Ga9H6ZDc3" placeholder="Recherche" onSelected={this.handleGifChange.bind(this)} style={{position : 'fixed'}} />
                                    </div>
                                }
                            </span>
                            <span className="camera-icon">
                                <label htmlFor={"uploadImageForComment-" + this.state.postID}>
                                    <input 
                                        ref={this.InputImage}
                                        type="file" 
                                        name="uploadImages" 
                                        id={"uploadImageForComment-" + this.state.postID} 
                                        accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                        onChange={this.handleImageChange.bind(this)} />
                                    <img src={configs.GetImage('camera.png')} alt="" />
                                </label>
                            </span>
                        </div>
                    </div>
                </div>

                {this.state.gif && 
                    <div className="UploadedGifDisplay">
                        <span>
                            <span className="remove" >
                                <i onClick={this.removeGif.bind(this)}>X</i>
                                <img src={this.state.gifSmall} className="gif" alt="" />
                            </span>
                        </span>
                    </div>
                }
                {this.state.images.length > 0 && 
                    <div className="UploadedImagesDisplay">
                        {this.state.images.map((image, index) => (
                            <span key={"image-"+index} style={{backgroundImage : 'url(' + image + ')'}} >
                                <span className="remove" ><i  data-target={index} onClick={this.removeImage.bind(this)}>X</i></span>
                            </span>
                        ))}
                    </div>
                }
            </div>
        )
    }
}

CreateComment.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(CreateComment));
