import Axios from 'axios' 
import config from '../config.json' 

class Global{
    
    
    IS_LOADED = false 
    IS_PROD = config.PROD
    API_URL    = config.API_URL
    APP_NAME   = config.APP_NAME
    APP_LOGO   = null
    LOGIN_TEXT = config.LOGIN_TEXT
    COLORS     = config.COLORS
    LOGOS      = config.LOGOS
    PUSHER     = config.PUSHER;
    GOOGLE_TRANSLATE_API_KEY = config.GOOGLE_TRANSLATE_API_KEY

    constructor(props){
        // console.log(12)
        if( !this.IS_LOADED ) this.getAppConfig()
    }  

    getDefaultWebColors(){
        return {
            "bodyBgColor": this.COLORS.primary,
            "headerBgColor":"#ffffff",
            "headerMenuColor":"#004b67ff",
            "headerActiveMenuColor":"#34a5f8ff",
            "communityColor": this.COLORS.primary,
            "communityBadgeColor":"#ff0000ff",
            "contactsColor":this.COLORS.primary
        }
    }

    getAppConfig (){
        document.body.style.opacity = "0"; 
        Axios
        .get(this.API_URL + "/admin/platformget")
        .then((res) => {
            this.IS_LOADED  = true; 
            document.body.style.opacity = "1"; 
            document.body.style.backgroundImage = "none"; 
            if( res.data.status ){

                let response = res.data.result[0];  

                this.APP_NAME = response.nom
                this.LOGIN_TEXT = response.messageaccueil

                this.APP_LOGO = response.logo && response.logo[0] ? this.API_URL+"/"+response.logo[0] : null
                this.FAVICON = response.favicon ? this.API_URL+"/"+response.favicon : null
                this.COLORS.primary = response.couleurprinc
                // this.COLORS.secondary = response.couleurtab
                this.COLORS.secondary = '#33a6f7'
                
                let advancedColors = response.colorweb ? JSON.parse(response.colorweb) : this.getDefaultWebColors()
                if(advancedColors){
                    this.COLORS.bodyBgColor = advancedColors.bodyBgColor
                    this.COLORS.headerBgColor = advancedColors.headerBgColor
                    this.COLORS.headerMenuColor = advancedColors.headerMenuColor
                    this.COLORS.headerActiveMenuColor = advancedColors.headerActiveMenuColor
                    this.COLORS.communityColor = advancedColors.communityColor
                    this.COLORS.communityBadgeColor = advancedColors.communityBadgeColor
                    this.COLORS.contactsColor = advancedColors.contactsColor 
                }
                

                this.applyConfigs(); 
            }
        }) 
    }

    applyConfigs(){
        // logo
        import('../components/header/Header').then(_header=>{  
            _header.default.setAppLogo(this.APP_LOGO)
        })
        import('../components/connexion/Connexion').then(_connexion=>{  
            _connexion.default.setAppLogo(this.APP_LOGO)
            _connexion.default.setAppSlogan(this.LOGIN_TEXT)
        })

        // app Name
        document.title = this.APP_NAME
        // favIcon
        document.getElementById('favicon').href = this.FAVICON
        
        
        document.documentElement.style.setProperty(`--primary-color`, this.COLORS.primary)
        document.documentElement.style.setProperty(`--secondary-color`, this.COLORS.secondary)
        document.documentElement.style.setProperty(`--body-bg-color`, this.COLORS.bodyBgColor)
        document.documentElement.style.setProperty(`--header-bg-color`, this.COLORS.headerBgColor)
        document.documentElement.style.setProperty(`--header-menu-color`, this.COLORS.headerMenuColor)
        document.documentElement.style.setProperty(`--header-active-menu-color`, this.COLORS.headerActiveMenuColor)
        document.documentElement.style.setProperty(`--header-profile-color`, this.COLORS.headerMenuColor)

        document.documentElement.style.setProperty(`--contact-color`, this.COLORS.contactsColor)

        document.documentElement.style.setProperty(`--communauty-name-color`, this.COLORS.communityColor)
        document.documentElement.style.setProperty(`--communauty-badge-color`, this.COLORS.communityBadgeColor)
    }

}
export default new Global();