import React, { Component } from 'react'
import SHA1 from 'sha1'
import axios from 'axios'
import Global from '../../inc/Global';
import configs from '../../inc/helpers';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';



class UserProfile extends Component {
    constructor(props){
        super(props);

        this.state = {
            userId : this.props.match.params.id,
            user : {}
        }

        this.profilSection = React.createRef();
    }
    componentDidMount(){
        const data = new FormData();
        data.append('id', this.state.userId)
        
        axios
        .post(Global.API_URL + "/api/infouser?c=" + SHA1(new Date()), data)
        .then((response) => {
            this.setState({
                user : response.data
            });

            this.profilSection.current.classList.remove('loading');
        }).catch((err) => {
            // console.log(err.message);
        });
    }
    async componentDidUpdate(PrevProps){
        if(PrevProps.match.params.id !== this.props.match.params.id){
            await this.setState({
                userId : this.props.match.params.id
            });

            const data = new FormData();
            data.append('id', this.state.userId)
            
            axios
            .post(Global.API_URL + "/api/infouser?c=" + SHA1(new Date()), data)
            .then((response) => {
                this.setState({
                    user : response.data
                });

                this.profilSection.current.classList.remove('loading');
            }).catch((err) => {
                // console.log(err.message);
            });
        }
    }

    render() {
        const {user} = this.state;
        
        
        
        return (
            <section className="content">
                <div className="wrap">
                    <div className="">
                        <div className={"user-" + user.id}>
                            <div className="_dlur-section p-10 bg-white text-center ">

                                <div ref={this.profilSection} className={"_user-details loading user-" + user.user_id}>
                                    <div className="after" style={{backgroundImage : 'url(' + configs.GetImage('spinner.svg') + ')'}}></div>
                                    <div className="_dlur-section p-10 bg-white text-center ">
                                        
                                        <figure className="user-thumb" style={(user.image && user.image !== 0) ?
                                            {backgroundImage: 'url(' + Global.API_URL +"/"+ user.image + ')'}
                                            :
                                            {backgroundColor: 'var(--primary-color)'}
                                            }>
                                                {(!(user.image && user.image !== 0) && user.nom) ? user.nom.charAt(0) : ""}
                                                {(!(user.image && user.image !== 0) && user.prenom) ? user.prenom.charAt(0) : ""}
                                                {(!(user.image && user.image !== 0) && !user.prenom && !user.nom) ? 'AA' : ""}
                                        </figure>
                                        <div className="username">
                                            {user.civilite && 
                                                <label>{user.civilite} </label>
                                            }
                                            {user.prenom.toLowerCase() + " " + user.nom.toLowerCase()}
                                        </div>
                                        <div className="p">
                                            {user.description &&
                                                <p style={{margin : '15px 0'}}>
                                                    {user.description}
                                                </p>
                                            }
                                            <p>
                                                <span>{user.fonction}</span>
                                                <br />
                                                {user.telephone &&
                                                    <span><label>Téléphone</label>  : {user.telephone}<br /></span>
                                                }
                                                {user.telephone &&
                                                    <span><label>Email</label> : {user.email}<br /></span>
                                                }
                                                {(user.year && user.day && user.month) &&
                                                    <span><label>Date de naissance</label> : {user.day}/{user.month}/{user.year}<br /></span>
                                                }
                                                {(user.year && user.day && user.month) &&
                                                    <span><label>Date de naissance</label> : {user.day}/{user.month}/{user.year}<br /></span>
                                                }
                                                {user.pays &&
                                                    <span><label>Pays</label> : {user.pays}<br /></span>
                                                }
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex jc-space-b"> 
                                <Link className="button lg m-t-10" onClick={(e) => {e.preventDefault(); this.props.history.goBack(null)}}>Retourner</Link>
                                <div>
                                    {configs.isAdmin() &&
                                        <Link to={{
                                            pathname: '/admin/utilisateur/modifier',
                                            state: { 
                                                id :  user.user_id,
                                                name : user.prenom,
                                                lastname : user.nom,
                                                job: user.fonction,
                                                email : user.email,
                                                tel : user.telephone,
                                                photo : user.image,
                                                casecoche : user.casecoche,
                                            }
                                        }} className="button lg m-t-10" >Modifier</Link>
                                    }
                                    {(!configs.isAdmin() && user.user_id === this.props.auth.user.user_id) &&
                                        <Link to="/utilisateur/profil/modifier" className="button lg m-t-10" >Modifier</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

UserProfile.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(UserProfile));